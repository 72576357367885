import {
  GET_SELLERS,
  GET_TEAMMEMBERS,
  GET_DISTRIBUTERS,
  GET_ITEMS,
} from "store/types";
import firebase from "../../config/firebase";
import { createNullCache } from "@algolia/cache-common";
import algoliasearch from "algoliasearch";
import { GET_ORDER } from "store/types";
const client = algoliasearch("AKYAZJGVV9", "f5306d66f87dbf20ad044268210656ed", {
  responsesCache: createNullCache(),
});
export const itemsIndex = client.initIndex("users");
export const productIndex = client.initIndex("items");
export const orderIndex = client.initIndex("orders");

export const getAllSellers =
  (item, search, batchSize = 10) =>
  async (dispatch, getState) => {
    await dispatch({ type: "LOADING", payload: true });
    try {
      if (search) {
        itemsIndex.search(search).then(async (response) => {
          dispatch({ type: "LOADING", payload: false });
          const objectIDs = response.hits.map((hit) => hit.objectID);
          const matchingItems = [];
          for (const objectID of objectIDs) {
            const docRef = await firebase
              .firestore()
              .collection("users")
              .doc(objectID);
            const docSnapshot = await docRef.get();
            if (docSnapshot.exists) {
              const itemData = { id: docSnapshot.id, ...docSnapshot.data() };
              if (itemData.role === "seller") {
                matchingItems.push(itemData);
              }
            }
          }
          dispatch({
            type: GET_SELLERS,
            payload: matchingItems,
            lastDocument: {},
            active: false,
          });
        });
      } else {
        const itemsRef = await firebase
          .firestore()
          .collection("users")
          .where("role", "==", "seller");
        let query = itemsRef.orderBy("createdAt", "desc").limit(batchSize);
        if (item && item.lastDocument) {
          query = query.startAfter(item.lastDocument);
        }
        let isFirstFetch = item === null;
        const unsubscribe = query.onSnapshot(async (snapshot) => {
          const tempData = [];
          for (const doc of snapshot.docs) {
            const itemData = doc.data();
            tempData.push({
              ...itemData,
              id: doc.id,
            });
          }
          const lastDocument =
            snapshot.docs.length > 0
              ? snapshot.docs[snapshot.docs.length - 1]
              : null;
          const newItem = { lastDocument };
          const previousQuestions = getState().sellers.allSellers;
          const newItems = tempData.filter((item) => {
            const exists = previousQuestions.some(
              (prevQuestion) => prevQuestion.id === item.id
            );
            return !exists;
          });
          let updatedItems = [];
          if (isFirstFetch) {
            isFirstFetch = false;
            dispatch({
              type: GET_SELLERS,
              payload: tempData,
              lastDocument: newItem,
              active: true,
            });
            dispatch({ type: "LOADING", payload: false });
          } else if (newItems.length > 0) {
            updatedItems = item
              ? [...previousQuestions, ...tempData]
              : tempData;
            dispatch({
              type: GET_SELLERS,
              payload: updatedItems,
              lastDocument: newItem,
              active: true,
            });
            dispatch({ type: "LOADING", payload: false });
          } else {
            updatedItems = item
              ? [...previousQuestions, ...tempData]
              : tempData;
            dispatch({
              type: GET_SELLERS,
              payload: updatedItems,
              lastDocument: newItem,
              active: false,
            });
            dispatch({ type: "LOADING", payload: false });
          }
        });
        return () => unsubscribe();
      }
    } catch (error) {
      console.log(error.message);
      dispatch({ type: "LOADING", payload: false });
    }
  };

export const getDistributers =
  (item, id, search, batchSize = 2) =>
  async (dispatch, getState) => {
    console.log(id, "id");
    console.log(search, "search");
    console.log(item, "item");

    // console.log("item, id, search", item, id, search);
    await dispatch({ type: "LOADING", payload: true });
    try {
      if (search) {
        itemsIndex.search(search).then(async (response) => {
          dispatch({ type: "LOADING", payload: false });
          const objectIDs = response.hits.map((hit) => hit.objectID);
          const matchingItems = [];
          for (const objectID of objectIDs) {
            const docRef = await firebase
              .firestore()
              .collection("users")
              .doc(objectID);
            const docSnapshot = await docRef.get();
            if (docSnapshot.exists) {
              const itemData = { id: docSnapshot.id, ...docSnapshot.data() };
              if (itemData.role === "distributor") {
                matchingItems.push(itemData);
              }
            }
          }
          dispatch({
            type: GET_DISTRIBUTERS,
            payload: matchingItems,
            lastDocument: {},
            active: false,
          });
        });
      } else {
        const itemsRef = await firebase
          .firestore()
          .collection("users")
          .where("creatorId", "==", id)
          .where("role", "==", "distributor");
        let query = itemsRef.orderBy("createdAt", "desc").limit(batchSize);
        if (item && item.lastDocument) {
          query = query.startAfter(item.lastDocument);
        }
        let isFirstFetch = item === null;
        const unsubscribe = query.onSnapshot(async (snapshot) => {
          const tempData = [];
          for (const doc of snapshot.docs) {
            const itemData = doc.data();
            tempData.push({
              ...itemData,
              id: doc.id,
            });
          }
          const lastDocument =
            snapshot.docs.length > 0
              ? snapshot.docs[snapshot.docs.length - 1]
              : null;
          const newItem = { lastDocument };
          const previousQuestions = getState().sellers.distributers;
          const newItems = tempData.filter((item) => {
            const exists = previousQuestions.some(
              (prevQuestion) => prevQuestion.id === item.id
            );
            return !exists;
          });
          let updatedItems = [];
          if (isFirstFetch) {
            isFirstFetch = false;
            console.log("FIRST FETCH", tempData);
            dispatch({
              type: GET_DISTRIBUTERS,
              payload: tempData,
              lastDocument: newItem,
              active: true,
            });
            dispatch({ type: "LOADING", payload: false });
          } else if (newItems.length > 0) {
            updatedItems = item
              ? [...previousQuestions, ...tempData]
              : tempData;
            dispatch({
              type: GET_DISTRIBUTERS,
              payload: updatedItems,
              lastDocument: newItem,
              active: true,
            });
            dispatch({ type: "LOADING", payload: false });
          } else {
            updatedItems = item
              ? [...previousQuestions, ...tempData]
              : tempData;
            dispatch({
              type: GET_DISTRIBUTERS,
              payload: updatedItems,
              lastDocument: newItem,
              active: false,
            });
            dispatch({ type: "LOADING", payload: false });
          }
        });
        return () => unsubscribe();
      }
    } catch (error) {
      console.log(error.message);
      dispatch({ type: "LOADING", payload: false });
    }
  };

export const getTeamMembers =
  (item, id, search, batchSize = 2) =>
  async (dispatch, getState) => {
    await dispatch({ type: "LOADING", payload: true });

    try {
      console.log({
        id: id,
        item: item,
        search: search,
      });
      if (search) {
        itemsIndex.search(search).then(async (response) => {
          dispatch({ type: "LOADING", payload: false });
          const objectIDs = response.hits.map((hit) => hit.objectID);
          const matchingItems = [];
          for (const objectID of objectIDs) {
            const docRef = await firebase
              .firestore()
              .collection("users")
              // .where("role","==","")
              .doc(objectID);
            const docSnapshot = await docRef.get();
            if (docSnapshot.exists) {
              const itemData = { id: docSnapshot.id, ...docSnapshot.data() };
              if (
                itemData.role &&
                ["logistics", "finance", "customer support"].includes(
                  itemData.role
                ) &&
                itemData.creatorId === id
              ) {
                matchingItems.push(itemData);
              }
            }
          }
          dispatch({
            type: GET_TEAMMEMBERS,
            payload: matchingItems,
            lastDocument: {},
            active: false,
          });
        });
      } else {
        const itemsRef = await firebase
          .firestore()
          .collection("users")
          .where("creatorId", "==", id)
          .where("role", "in", ["logistics", "finance", "customer support"]);
        let query = itemsRef.orderBy("createdAt", "desc").limit(batchSize);
        if (item && item.lastDocument) {
          query = query.startAfter(item.lastDocument);
        }
        let isFirstFetch = item === null;
        const unsubscribe = query.onSnapshot(async (snapshot) => {
          const tempData = [];
          for (const doc of snapshot.docs) {
            const itemData = doc.data();
            tempData.push({
              ...itemData,
              id: doc.id,
            });
          }
          const lastDocument =
            snapshot.docs.length > 0
              ? snapshot.docs[snapshot.docs.length - 1]
              : null;
          const newItem = { lastDocument };
          const previousQuestions = getState().sellers.teamMembers;
          const newItems = tempData.filter((item) => {
            const exists = previousQuestions.some(
              (prevQuestion) => prevQuestion.id === item.id
            );
            return !exists;
          });
          let updatedItems = [];
          if (isFirstFetch) {
            isFirstFetch = false;
            console.log("first fetch", tempData);
            dispatch({
              type: GET_TEAMMEMBERS,
              payload: tempData,
              lastDocument: newItem,
              active: true,
            });
            dispatch({ type: "LOADING", payload: false });
          } else if (newItems.length > 0) {
            updatedItems = item
              ? [...previousQuestions, ...tempData]
              : tempData;
            dispatch({
              type: GET_TEAMMEMBERS,
              payload: updatedItems,
              lastDocument: newItem,
              active: true,
            });
            dispatch({ type: "LOADING", payload: false });
          } else {
            updatedItems = item
              ? [...previousQuestions, ...tempData]
              : tempData;
            dispatch({
              type: GET_TEAMMEMBERS,
              payload: updatedItems,
              lastDocument: newItem,
              active: false,
            });
            dispatch({ type: "LOADING", payload: false });
          }
        });
        return () => unsubscribe();
      }
    } catch (error) {
      console.log(error.message);
      dispatch({ type: "LOADING", payload: false });
    }
  };
// export const getAllSellers = () => async (dispatch) => {
//   try {
//     firebase
//       .firestore()
//       .collection("users")
//       .where("role", "==", "seller")
//       .onSnapshot((querySnapshot) => {
//         let sellers = [];
//         for (let doc of querySnapshot.docs) {
//           sellers.push({
//             id: doc.id,
//             ...doc.data(),
//           });
//         }

//         dispatch({ type: GET_SELLERS, payload: sellers });
//       });
//   } catch (error) {
//     alert("Not found ! any sellers");
//   }
// };

// export const getDistributers = (id) => async (dispatch) => {
//   try {
//     firebase
//       .firestore()
//       .collection("users")
//       .where("createrId", "==", id)
//       .onSnapshot((querySnapshot) => {
//         let distributers = [];
//         for (let doc of querySnapshot.docs) {
//           distributers.push({
//             id: doc.id,
//             ...doc.data(),
//           });
//         }

//         dispatch({ type: GET_DISTRIBUTERS, payload: distributers });
//       });
//   } catch (error) {
//     alert("Not found ! any distributers");
//   }
// };

// export const getTeamMembers = (id) => async (dispatch) => {
//   try {
//     firebase
//       .firestore()
//       .collection("users")
//       .where("sellerId", "==", id)
//       .onSnapshot((querySnapshot) => {
//         let team = [];
//         for (let doc of querySnapshot.docs) {
//           team.push({
//             id: doc.id,
//             ...doc.data(),
//           });
//         }

//         dispatch({ type: GET_TEAMMEMBERS, payload: team });
//       });
//   } catch (error) {
//     alert("Not found ! any team members");
//   }
// };

// export const getAllProducts = (id) => async (dispatch) => {
//   try {
//     firebase
//       .firestore()
//       .collection("items")
//       .where("createrId", "==", id)
//       .onSnapshot((querySnapshot) => {
//         let items = [];
//         for (let doc of querySnapshot.docs) {
//           items.push({
//             id: doc.id,
//             ...doc.data(),
//           });
//         }

//         dispatch({ type: GET_ITEMS, payload: items });
//       });
//   } catch (error) {
//     alert("Not found ! any items members");
//   }
// };

export const getAllProducts =
  (item, id, search, batchSize = 2) =>
  async (dispatch, getState) => {
    await dispatch({ type: "LOADING", payload: true });
    try {
      console.log({
        id: id,
        item: item,
        search: search,
      });
      if (search) {
        productIndex.search(search).then(async (response) => {
          dispatch({ type: "LOADING", payload: false });
          const objectIDs = response.hits.map((hit) => hit.objectID);
          const matchingItems = [];
          for (const objectID of objectIDs) {
            const docRef = await firebase
              .firestore()
              .collection("items")
              .doc(objectID);
            const docSnapshot = await docRef.get();
            if (docSnapshot.exists) {
              const itemData = { id: docSnapshot.id, ...docSnapshot.data() };
              if (itemData.creatorId === id) {
                matchingItems.push(itemData);
              }
            }
          }
          dispatch({
            type: GET_ITEMS,
            payload: matchingItems,
            lastDocument: {},
            active: false,
          });
        });
      } else {
        const itemsRef = await firebase
          .firestore()
          .collection("items")
          .where("creatorId", "==", id);
        let query = itemsRef.orderBy("createdAt", "desc").limit(batchSize);
        if (item && item.lastDocument) {
          query = query.startAfter(item.lastDocument);
        }
        let isFirstFetch = item === null;
        const unsubscribe = query.onSnapshot(async (snapshot) => {
          const tempData = [];
          for (const doc of snapshot.docs) {
            const itemData = doc.data();
            tempData.push({
              ...itemData,
              id: doc.id,
            });
          }
          const lastDocument =
            snapshot.docs.length > 0
              ? snapshot.docs[snapshot.docs.length - 1]
              : null;
          const newItem = { lastDocument };
          const previousQuestions = getState().sellers.products;
          const newItems = tempData.filter((item) => {
            const exists = previousQuestions.some(
              (prevQuestion) => prevQuestion.id === item.id
            );
            return !exists;
          });
          let updatedItems = [];
          if (isFirstFetch) {
            isFirstFetch = false;

            dispatch({
              type: GET_ITEMS,
              payload: tempData,
              lastDocument: newItem,
              active: true,
            });
            dispatch({ type: "LOADING", payload: false });
          } else if (newItems.length > 0) {
            updatedItems = item
              ? [...previousQuestions, ...tempData]
              : tempData;
            dispatch({
              type: GET_ITEMS,
              payload: updatedItems,
              lastDocument: newItem,
              active: true,
            });
            dispatch({ type: "LOADING", payload: false });
          } else {
            updatedItems = item
              ? [...previousQuestions, ...tempData]
              : tempData;
            dispatch({
              type: GET_ITEMS,
              payload: updatedItems,
              lastDocument: newItem,
              active: false,
            });
            dispatch({ type: "LOADING", payload: false });
          }
        });
        return () => unsubscribe();
      }
    } catch (error) {
      console.log(error.message);
      dispatch({ type: "LOADING", payload: false });
    }
  };

export const getAllOrders =
  (item, id, search, status = "", batchSize = 2) =>
  async (dispatch, getState) => {
    await dispatch({ type: "LOADING", payload: true });
    try {
      console.log({
        id: id,
        item: item,
        search: search,
      });
      if (search) {
        orderIndex.search(search).then(async (response) => {
          dispatch({ type: "LOADING", payload: false });
          console.log(response.hits, "response.hits");
          const objectIDs = response.hits.map((hit) => hit.objectID);
          console.log(objectIDs, "objectId");
          const matchingItems = [];
          for (const objectID of objectIDs) {
            const docRef = await firebase
              .firestore()
              .collection("orders")
              .doc(objectID);
            const docSnapshot = await docRef.get();
            if (docSnapshot.exists) {
              matchingItems.push({ id: docSnapshot.id, ...docSnapshot.data() });
            }
          }
          dispatch({
            type: GET_ORDER,
            payload: matchingItems,
            lastDocument: {},
            active: false,
          });
        });
      } else {
        const itemsRef = await firebase
          .firestore()
          .collection("orders")
          .where("sellerId", "==", id);
        let query = itemsRef.orderBy("createdAt", "desc").limit(batchSize);
        if (status) {
          query = query.where("status", "==", status);
        }
        if (item && item.lastDocument) {
          query = query.startAfter(item.lastDocument);
        }
        let isFirstFetch = item === null;
        const unsubscribe = query.onSnapshot(async (snapshot) => {
          const tempData = [];
          for (const doc of snapshot.docs) {
            const itemData = doc.data();
            const customerDocRef = await firebase
              .firestore()
              .collection("users")
              .doc(itemData.customerId);
            const customerDocSnapshot = await customerDocRef.get();
            if (customerDocSnapshot.exists) {
              const customerData = customerDocSnapshot.data();
              const username = customerData.username;
              itemData.username = username;
              await firebase
                .firestore()
                .collection("orders")
                .doc(doc.id)
                .update({ username });
            }
            tempData.push({
              ...itemData,
              id: doc.id,
            });
          }
          const lastDocument =
            snapshot.docs.length > 0
              ? snapshot.docs[snapshot.docs.length - 1]
              : null;
          const newItem = { lastDocument };
          const previousQuestions = getState().sellers.orders;
          const newItems = tempData.filter((item) => {
            const exists = previousQuestions.some(
              (prevQuestion) => prevQuestion.id === item.id
            );
            return !exists;
          });
          let updatedItems = [];
          if (isFirstFetch) {
            isFirstFetch = false;
            dispatch({
              type: GET_ORDER,
              payload: tempData,
              lastDocument: newItem,
              active: true,
            });
            dispatch({ type: "LOADING", payload: false });
          } else if (newItems.length > 0) {
            updatedItems = item
              ? [...previousQuestions, ...tempData]
              : tempData;
            dispatch({
              type: GET_ORDER,
              payload: updatedItems,
              lastDocument: newItem,
              active: true,
            });
            dispatch({ type: "LOADING", payload: false });
          } else {
            updatedItems = item
              ? [...previousQuestions, ...tempData]
              : tempData;
            dispatch({
              type: GET_ORDER,
              payload: updatedItems,
              lastDocument: newItem,
              active: false,
            });
            dispatch({ type: "LOADING", payload: false });
          }
        });
        return () => unsubscribe();
      }
    } catch (error) {
      console.log(error.message);
      dispatch({ type: "LOADING", payload: false });
    }
  };
