import { GET_BUYERS } from "../types";

const initialState = {
  allBuyers: [],
  loader: false,
  active: true,
  lastDocument: {},
};

export default function buyerReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BUYERS:
      return {
        ...state,
        allBuyers: action.payload,
        active: action.active,
        lastDocument: action.lastDocument,
      };
    case "LOADING":
      return {
        ...state,
        loader: action.payload,
      };

    default:
      return { ...state };
  }
}
