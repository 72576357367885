import { Card, CardHeader, Container, Row, Button, Col } from "reactstrap";
// core components
import OnlyHeader from "components/Headers/OnlyHeader.js";
import { useEffect, useState } from "react";
import AddDiscordModel from "components/PopupModels/AddDiscord";
import { useDispatch } from "react-redux";
import { getDiscordLink } from "store/actions/settingsAction";

const Setting = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editData, setEditData] = useState("");

  useEffect(() => {
    dispatch(getDiscordLink());
  }, []);
  return (
    <>
      <OnlyHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <AddDiscordModel
          isOpen={isModalOpen}
          data={editData}
          toggle={() => setIsModalOpen(!isModalOpen)}
        />
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Setting</h3>
              </CardHeader>
              <Row className="align-items-center">
                <Col xs="12">
                  <Card className="p-4">
                    <h3 className="mb-0">Discord Channel </h3>
                    <Button
                      color="primary"
                      className="mt-4 py-2"
                      onClick={() => {
                        setIsModalOpen(true);
                        setEditData("");
                      }}
                      size="sm"
                    >
                      Add Discord Channel
                    </Button>
                  </Card>
                </Col>
              </Row>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Setting;
