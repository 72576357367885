import {GET_PRODUCT_DISPUTE_REVIEWS } from "../types";

const initialState = {
  disputeProductReviews: [],
  productLoader: false,
  productActive: true,
  productLastDocument: {},
};

export default function productDisputeReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT_DISPUTE_REVIEWS:
      return {
        ...state,
        disputeProductReviews: action.payload,
        productActive: action.productActive,
        productLastDocument: action.productLastDocument,
      };
      case "LOADING":
      return {
        ...state,
        productLoader: action.payload,
      };
    default:
      return { ...state };
  }
}