import { LOGIN, LOGIN_LOADER } from "../types";

const initialState = {
  loginLoading: false,
};

export default function authReducer(state = initialState, { type, payload }) {
  switch (type) {
    case LOGIN_LOADER:
      return {
        ...state,
        loginLoading: payload,
      };

    default:
      return {
        ...state,
      };
  }
}
