import firebase from "firebase";
import "firebase/messaging";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyBTV0HiuRKH8TG9LrzgKS_ex0vUj0F7_rY",
  authDomain: "fullylocal-multi-vendor.firebaseapp.com",
  projectId: "fullylocal-multi-vendor",
  storageBucket: "fullylocal-multi-vendor.appspot.com",
  messagingSenderId: "376676634166",
  appId: "1:376676634166:web:c9e42ef3832235d1d7d297",
  measurementId: "G-QE87461W3C",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = firebase.messaging();

export default firebase;
