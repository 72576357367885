// Root Reducer

import { combineReducers } from "redux";
import authReducer from "./authReducer";
import authUserReducer from "./authUser";
import blogsReducer from "./blogsReducer";
import videosReducer from "./youtubeVideosReducer";
import categoryReducer from "./categoryReducer";
import settingsReducer from "./settingsReducer";
import sellerReducer from "./sellerReducers";
import buyerReducer from "./buyerReducer";
import dashboardStatsReducer from "./statsReducer";
import ordersReducer from "./ordersReducer";
import reviewsReducer from "./reviewsReducer";
import productDisputeReducer from "./productDisputeReducer";
import adminReducers from "./adminReducer";
import ticketReducer from "./ticketReducer";

import inviteVendorReducer from "./InviteVendorsReducer";
export const appReducer = combineReducers({
  authUser: authUserReducer,
  auth: authReducer,
  blogs: blogsReducer,
  youtubeVideos: videosReducer,
  categories: categoryReducer,
  settings: settingsReducer,
  sellers: sellerReducer,
  buyers: buyerReducer,
  dashboardStats: dashboardStatsReducer,
  orders: ordersReducer,
  reviews: reviewsReducer,
  productDispute: productDisputeReducer,
  admin: adminReducers,
  ticket: ticketReducer,
  vendors: inviteVendorReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
export default rootReducer;
