import { useDispatch } from "react-redux";

import { Button, Modal, ModalBody, ModalFooter, Row } from "reactstrap";
import { deleteCategory } from "store/actions/categoriesAction";

function DeleteCategoryModel({ isOpen, toggle, data }) {
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(
      deleteCategory(data?.id, () => {
        toggle();
      })
    );
  };
  return (
    <Modal toggle={toggle} isOpen={isOpen} centered="true">
      <ModalBody>
        <div className="d-flex justify-content-center align-items-center mt-5">
          <h4> Are you sure you want to Delete Category ?</h4>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={handleDelete}>
          Yes
        </Button>
        <Button color="warning" onClick={toggle}>
          No
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default DeleteCategoryModel;
