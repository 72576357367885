import OnlyHeader from "components/Headers/OnlyHeader";
import QuestionAnswer from "components/common/QuestionAnswer";
import ThreeSixtyViewer from "components/common/ThreeSixtyViewer";
import React, { useEffect, useState } from "react";
import { MdArrowBackIosNew } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import ReactStars from "react-stars";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

function ProductDetails() {
  const location = useLocation();
  const state = location.state;
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const getContrastColor = (backgroundColor) => {
    if (!backgroundColor || typeof backgroundColor !== "string") {
      return "#000";
    }
    const rgb = backgroundColor.match(/\d+/g);
    if (!rgb || rgb.length !== 3) {
      return "#000";
    }
    const threshold = 128;
    const brightness = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
    return brightness > threshold ? "#000" : "#fff";
  };

  const handleIconClick = (image) => {
    setPreviewImage(image);
  };

  const closePreview = () => {
    setPreviewImage(null);
  };

  useEffect(() => {
    const loaderTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(loaderTimeout);
  }, []);

  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Card>
          {" "}
          <CardHeader className="border-0 ">
            <Row>
              <Col lg={3} className="d-flex align-items-center">
                <MdArrowBackIosNew
                  className="mx-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => history.goBack()}
                />
                <h3 className="mb-0  text-capitalize">Detail</h3>
              </Col>
            </Row>
          </CardHeader>
          {isLoading ? (
            <div className="d-flex align-items-center justify-content-center mb-2">
              <Spinner size="lg" />
            </div>
          ) : (
            <CardBody className="mainCardHeader">
              <Row className="mt-4">
                <Col md={6} sm={12} className="mb-3">
                  <Label className="font-weight-bold ">Name:</Label>
                  <span
                    className="ml-2"
                    style={{
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      borderRadius: "8px",
                      padding: "10px",
                    }}
                  >
                    {state?.detail?.name}
                  </span>
                </Col>
                <Col md={6} sm={12} className="mb-3">
                  <Label className="font-weight-bold ">Price:</Label>
                  <span
                    className="ml-2"
                    style={{
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      borderRadius: "8px",
                      padding: "10px",
                    }}
                  >
                    ${state?.detail?.price}
                  </span>
                </Col>
              </Row>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {state?.detail?.images.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      position: "relative",
                      marginRight: "10px",
                      marginBottom: "10px",
                      maxWidth: "15%",
                    }}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                  >
                    <img
                      src={item}
                      className="img-fluid rounded shadow"
                      alt={`Product Image ${index}`}
                      style={{
                        width: "100%",
                        height: "auto",
                        border: "1px solid #ddd",
                        display: "block",
                      }}
                      onClick={() => handleIconClick(item)}
                    />
                    {hoveredIndex === index && (
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-start",
                          background: "rgba(255, 255, 255, 0.7)",
                        }}
                      >
                        <FaEye
                          style={{
                            fontSize: "24px",
                            cursor: "pointer",
                            color: "#333",
                            margin: "5px",
                          }}
                          onClick={() => handleIconClick(item)}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div className="mb-4 mt-4">
                <Col lg={12}>
                  <Label className="font-weight-bold">Colors:</Label>
                  <div
                    className="color-list-container w-100"
                    style={{ overflowX: "auto" }}
                  >
                    {state?.detail?.color && (
                      <ul
                        className="list-unstyled color-list"
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          padding: 0,
                        }}
                      >
                        {state?.detail?.color.map((color, index) => (
                          <li
                            key={index}
                            className="color-item"
                            style={{
                              listStyle: "none",
                              marginRight: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            <div
                              className="color-label"
                              style={{
                                backgroundColor: color?.value,
                                padding: "5px",
                                borderRadius: "5px",
                                color: getContrastColor(color?.value),
                              }}
                            >
                              {color?.label}
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </Col>
              </div>
              <Row className="mb-3">
                <Col>
                  <Label className="font-weight-bold ">Description:</Label>
                  <p className="ml-2">{state?.detail?.description}</p>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col sm={4} className="d-flex">
                  <Label className="font-weight-bold mr-1">Rating:</Label>
                  <ReactStars value={state?.detail?.rating} edit={false} />
                </Col>
                <Col sm={4}>
                  <Label className="font-weight-bold ">Ecosystem:</Label>
                  <span className="ml-2">{state?.detail?.ecoSystem}</span>
                </Col>
                <Col sm={4}>
                  <Label className="font-weight-bold ">Power:</Label>
                  <span className="ml-2">{state?.detail?.power}</span>
                </Col>
              </Row>
              <div className="mb-3">
                <Label className="d-block font-weight-bold ">
                  Shipping Time
                </Label>
              </div>
              <Row className="mb-3">
                <Col sm={4}>
                  <Label className="font-weight-bold ">Local:</Label>
                  <span className="ml-2">
                    {state?.detail?.shippingTimeLocal}
                  </span>
                </Col>
                <Col sm={4}>
                  <Label className="font-weight-bold ">International:</Label>
                  <span className="ml-2">
                    {state?.detail?.shippingTimeInternational}
                  </span>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={3}>
                  <div className="d-flex flex-column align-items-center">
                    {state?.detail?.videoFile ? (
                      <video width="100%" controls className="p-3">
                        <source
                          src={state?.detail?.videoFile}
                          type="video/mp4"
                        />
                      </video>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </Row>
              <ThreeSixtyViewer threeSixtyUrl={state?.threeSixtyUrl} />
              {state?.cadFile && (
                <Row className="mb-3">
                  <Col md={12}>
                    <h4>CAD File</h4>
                    <a href={state?.cadFile} download="cad_file.zip">
                      Download CAD File
                    </a>
                  </Col>
                </Row>
              )}
              <Row className="mb-3">
                <Col md={12}>
                  <Label className="font-weight-bold ">
                    Recommended Products:
                  </Label>{" "}
                  {state?.recommendedProduct && (
                    <Row>
                      {state?.recommendedProduct.map((product, index) => (
                        <Col key={index} sm={6} md={4} lg={3} className="mb-3">
                          <Card>
                            <CardBody>
                              <h5>
                                {typeof product === "object" && product.label
                                  ? product.label
                                  : product}
                              </h5>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  )}
                </Col>
              </Row>
              <div>
                <Label className="font-weight-bold">Questions/Answers</Label>{" "}
                <QuestionAnswer questionAnswers={state?.questionAnswer} />
              </div>
            </CardBody>
          )}
          {previewImage && (
            <Modal isOpen={previewImage !== null} toggle={closePreview}>
              <ModalHeader toggle={closePreview} className="border-bottom">
                <h3 className="mb-0">Image Preview</h3>
              </ModalHeader>
              <ModalBody>
                <img
                  src={previewImage}
                  width="100%"
                  className="mb-3 border shadow-lg"
                  alt="Preview"
                />
              </ModalBody>
            </Modal>
          )}
        </Card>
      </Container>
    </>
  );
}

export default ProductDetails;
