import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import OnlyHeader from "components/Headers/OnlyHeader.js";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { getOrdersAction } from "store/actions/ordersAction";
import moment from "moment";
import { MdArrowBackIosNew } from "react-icons/md";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AllOrders = () => {
  const { orders } = useSelector((state) => state.orders);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      const fetchData = async () => {
        await dispatch(getOrdersAction(id));
        setIsLoading(false);
      };

      fetchData();
    }
  }, [id]);

  return (
    <>
      <OnlyHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader>
                <Row>
                  <Col lg={3} className="d-flex align-items-center">
                    <MdArrowBackIosNew
                      className="mx-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.goBack()}
                    />
                    <h3 className="mb-0  text-capitalize">Orders</h3>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Order Id</th>
                    <th scope="col">Status</th>
                    <th scope="col">Created At</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                {isLoading ? (
                  <tbody>
                    <tr>
                      <td colSpan="5" className="text-center">
                        <Spinner size="lg" />
                      </td>
                    </tr>
                  </tbody>
                ) : orders?.length === 0 ? (
                  <tbody>
                    <tr className="mt-2">
                      <td>No order found.</td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {orders.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td scope="row">{data.orderId}</td>
                          <td>{data?.status}</td>
                          <td>
                            {moment(data?.createdAt?.toDate()).format(
                              "DD:MM:YYYY"
                            )}
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <Link to={`/admin/orderDetails/${data?.orderId}`}>
                                <Button color="primary" size="sm">
                                  <i className="fas fa-eye" />
                                </Button>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default AllOrders;
