import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Media,
  Row,
  Spinner,
  Table,
} from "reactstrap";
// core components
import OnlyHeader from "components/Headers/OnlyHeader.js";
import AddYoutubeModel from "components/PopupModels/AddYoutubeModel";
import DeleteVideoModel from "components/PopupModels/DeleteVideoModal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllVideos, getMoreVideos } from "store/actions/youtubeVideosAction";

const YoutubeVideos = () => {
  const dispatch = useDispatch();
  const { allVideos, loadMoreLoader } = useSelector(
    (state) => state.youtubeVideos
  );

  const [videoData, setVideoData] = useState("");
  const loadMore = () => {
    const lastItem = allVideos[allVideos?.length - 1];
    dispatch(getMoreVideos(lastItem));
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editData, setEditData] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredVideos = allVideos.filter((video) =>
    video.videoTitle.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      await dispatch(getAllVideos());
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };

    fetchData();
  }, []);

  return (
    <>
      <OnlyHeader />
      {/* Page content */}

      <AddYoutubeModel
        isOpen={isModalOpen}
        data={editData}
        toggle={() => setIsModalOpen(!isModalOpen)}
      />
      <DeleteVideoModel
        isOpen={isVideoModalOpen}
        data={videoData}
        toggle={() => setIsVideoModalOpen(!isVideoModalOpen)}
      />

      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-lg-flex w-100 justify-content-between">
                <h3 className="mb-0">Youtube Videos</h3>
                <div style={{maxWidth:"200px"}}>
                  <InputGroup className="input-group-alternative ml-0 ml-lg-4 my-3 my-lg-0 w-100">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Search"
                      type="text"
                      value={searchQuery}
                      onChange={handleSearch}
                    />{" "}
                  </InputGroup>
                </div>
                <Button
                  color="primary"
                  onClick={() => {
                    setIsModalOpen(true);
                    setEditData("");
                  }}
                >
                  Add Video
                </Button>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Title</th>
                    <th scope="col">Embeded URL</th>
                    <th scope="col">Actions</th>
                    <th scope="col" />
                  </tr>
                </thead>

                {isLoading ? (
                  <tbody>
                    <tr>
                      <td colSpan="5" className="text-center">
                        <Spinner size="lg" />
                      </td>
                    </tr>
                  </tbody>
                ) : filteredVideos?.length === 0 ? (
                  <tbody>
                    <tr className="mt-2">
                      <td>No video available.</td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {filteredVideos?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm truncate">
                                  {data?.videoTitle}
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td>{data?.videoUrl}</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <Button
                                color="primary"
                                onClick={() => {
                                  setIsModalOpen(true);
                                  setEditData(data);
                                }}
                                size="sm"
                              >
                                <i className="fas fa-pencil-alt" />
                              </Button>
                              <Button
                                color="danger"
                                onClick={() => {
                                  setIsVideoModalOpen(true);
                                  setVideoData(data);
                                }}
                                size="sm"
                              >
                                <i className="fas fa-trash-alt" />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </Table>
            </Card>
          </div>
        </Row>
        <Row>
          <Col className="my-3">
            <div className="d-flex justify-content-center align-items-center">
              <Button
                onClick={loadMore}
                disabled={filteredVideos?.length < 10 ? true : false}
                size="sm"
                color="primary"
              >
                {loadMoreLoader ? <Spinner size="sm" /> : "Load More"}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default YoutubeVideos;
