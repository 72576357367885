import React, { useEffect, useState } from "react";
import { Collapse, Form } from "reactstrap";
import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer";

export default function ThreeSixtyViewer({ threeSixtyUrl }) {
  console.log("threeSixtyUrl: ", threeSixtyUrl);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (typeof threeSixtyUrl != "string")
      return () => {
        URL.revokeObjectURL(threeSixtyUrl);
      };
  }, [threeSixtyUrl]);

  return (
    <div className="my-3 border border-2 p-2 shadow-sm">
      <Form className="my-2">
        <div
          className="d-flex justify-content-between align-items-center cursor"
          onClick={toggle}
        >
          <h4 className="mb-0 pb-0 font-weight-bold">360 Viewer</h4>
          <i
            className={`fas ${isOpen ? "fa-chevron-up" : "fa-chevron-down"}`}
          ></i>
        </div>
        <Collapse isOpen={isOpen}>
          <div className="App">
            {threeSixtyUrl ? (
              <ReactPhotoSphereViewer
                src={
                  typeof threeSixtyUrl != "string"
                    ? URL.createObjectURL(threeSixtyUrl)
                    : `https://cors-platform.herokuapp.com/${threeSixtyUrl}`
                }
                height={"50vh"}
                width={"100%"}
              ></ReactPhotoSphereViewer>
            ) : (
              <div className="text-danger">No image selected.</div>
            )}
          </div>
        </Collapse>
      </Form>
    </div>
  );
}
