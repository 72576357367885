import {
  GET_DISTRIBUTERS,
  GET_TEAMMEMBERS,
  GET_SELLERS,
  GET_ITEMS,
  GET_ORDER,
} from "../types";

const initialState = {
  allSellers: [],
  distributers: [],
  teamMembers: [],
  products: [],
  orders: [],
  loader: false,
  active: true,
  lastDocument: {},
};

export default function sellerReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SELLERS:
      return {
        ...state,
        allSellers: action.payload,
        active: action.active,
        lastDocument: action.lastDocument,
      };

    case GET_DISTRIBUTERS:
      return {
        ...state,
        distributers: action.payload,
        active: action.active,
        lastDocument: action.lastDocument,
      };
    case GET_TEAMMEMBERS:
      return {
        ...state,
        teamMembers: action.payload,
        active: action.active,
        lastDocument: action.lastDocument,
      };
    case GET_ITEMS:
      return {
        ...state,
        products: action.payload,
        active: action.active,
        lastDocument: action.lastDocument,
      };
    case GET_ORDER:
      return {
        ...state,
        orders: action.payload,
        active: action.active,
        lastDocument: action.lastDocument,
      };
    case "LOADING":
      return {
        ...state,
        loader: action.payload,
      };
    default:
      return { ...state };
  }
}
