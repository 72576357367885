import React from "react";
import { Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";

const VideoModal = ({ isOpen, toggle, selectedItemVideo }) => {

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} className="border-bottom">
        <h3 className="mb-0">Product Video</h3>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            {selectedItemVideo ? (
              <video width="100%" controls className="mb-3 border shadow-lg">
                <source src={selectedItemVideo} type="video/mp4" />
              </video>
            ) : (
              <div className="text-center">
                <p>No video available</p>
              </div>
            )}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default VideoModal;
