import React, { useState } from 'react';
import { Collapse, Card, CardBody, CardHeader } from 'reactstrap';

const QuestionAnswer = ({ questionAnswers }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  if (!questionAnswers || questionAnswers.length === 0) {
    return "";
  }

  return (
    <div>
      {questionAnswers.map((faq, index) => (
        <Card key={index} className="mb-2">
          <CardHeader onClick={() => toggleAccordion(index)} style={{ cursor: 'pointer' }}>
            <h5 className="mb-0">{faq.question}</h5>
          </CardHeader>
          <Collapse isOpen={activeIndex === index}>
            <CardBody>
              <p>{faq.answer}</p>
            </CardBody>
          </Collapse>
        </Card>
      ))}
    </div>
  );
};

export default QuestionAnswer;
