import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";
// core components
import { DotPulse } from "@uiball/loaders";
import OnlyHeader from "components/Headers/OnlyHeader.js";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllProducts } from "store/actions/SellersAction";
import debounce from "lodash/debounce";
import ImageModel from "components/Modal/ImageModel";
import VideoModal from "components/Modal/VideoModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { MdArrowBackIosNew } from "react-icons/md";

const ProductDetail = () => {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const imagesToggle = () => setImgModal(!imgModal);
  const VideoToggle = () => setVideoModal(!videoModal);
  const [imgModal, setImgModal] = useState(false);
  const [videoModal, setVideoModal] = useState(false);
  const [selectedItemImages, setSelectedItemImages] = useState([]);
  const [selectedItemVideo, setSelectedItemVideo] = useState();

  const { products, active, lastDocument, loader } = useSelector(
    (state) => state.sellers
  );
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [lastDocuments, setLastDocuments] = useState();

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    notation: "compact",
    compactDisplay: "short",
  });

  const handleSearch = debounce(async (e) => {
    try {
      await dispatch(getAllProducts(null, id, e.target.value));
      setIsLoading(false);
    } catch (error) {
      console.error("An error occurred:", error);
      setIsLoading(false);
    }
  }, 3000);

  const handleLoadMore = () => {
    setSearch("");
    dispatch(getAllProducts(lastDocuments, id, ""));
  };
  useEffect(() => {
    setLastDocuments(lastDocument);
  }, [lastDocument]);

  useEffect(() => {
    dispatch(getAllProducts(null, id, ""));
  }, [id]);
  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 ">
                <div className="d-flex justify-content-between align-items-center mx-0 mx-lg-3">
                  <h3 className="mb-0">
                    {" "}
                    <MdArrowBackIosNew
                      className="mx-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.goBack()}
                    />
                    Products
                  </h3>
                  <div className="w-50">
                    <div className="position-relative">
                      <Input
                        className="w-50 float-right mt-1"
                        type="text"
                        placeholder="Search"
                        onChange={(e) => {
                          setIsLoading(true);
                          handleSearch(e);
                        }}
                      />
                      {isLoading ? (
                        <span
                          style={{
                            position: "absolute",
                            top: "20px",
                            right: "10px",
                          }}
                        >
                          <DotPulse size={25} speed={1.3} color="black" />
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </CardHeader>
              <Row>
                <Col>
                  <Card className="mb-4">
                    <CardBody className="mainCardHeader">
                      {!loader ? (
                        <Table responsive>
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Name</th>
                              <th scope="col">Price</th>
                              <th scope="col">Images</th>
                              <th>Video</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {products &&
                              products.map((item, index) => (
                                <tr key={index}>
                                  <td scope="row">
                                    <span className="mb-0 text-sm">
                                      {item?.detail?.name}
                                    </span>
                                  </td>
                                  <td>
                                    {item?.detail?.price
                                      ? formatter.format(item?.detail?.price)
                                      : "Not Available"}
                                  </td>
                                  <td>
                                    <Button
                                      color="success"
                                      onClick={() => {
                                        imagesToggle();
                                        setSelectedItemImages(
                                          item?.detail?.images
                                        );
                                      }}
                                      size="sm"
                                    >
                                      View images
                                    </Button>
                                  </td>
                                  <td>
                                    <Button
                                      color="info"
                                      size="sm"
                                      onClick={() => {
                                        VideoToggle();
                                        setSelectedItemVideo(
                                          item?.detail?.videoFile
                                        );
                                      }}
                                    >
                                      View Video
                                    </Button>
                                  </td>
                                  <td>
                                    <Button
                                      color="primary"
                                      size="sm"
                                      onClick={() =>
                                        history.push({
                                          pathname: "/admin/seller/single-item",
                                          state: { ...item },
                                        })
                                      }
                                    >
                                      Details
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      ) : (
                        <div className="text-center">
                          <Spinner size="lg" />
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Card>
            <div className="d-flex justify-content-center align-items-center mt-3">
              {loader == false && products.length > 0 ? (
                <>
                  <p className="text-center">
                    {active == false ? "No more items" : ""}
                  </p>
                  <Button
                    onClick={handleLoadMore}
                    className="bg-primary mx-3 text-secondary  mb-2 btn-sm"
                    disabled={active ? false : true}
                  >
                    Load more
                  </Button>
                </>
              ) : (
                "Products not found !"
              )}
            </div>
          </div>
        </Row>
      </Container>
      <ImageModel
        isOpen={imgModal}
        selectedItemImages={selectedItemImages}
        toggle={imagesToggle}
      />
      <VideoModal
        isOpen={videoModal}
        selectedItemVideo={selectedItemVideo}
        toggle={VideoToggle}
      />
    </>
  );
};

export default ProductDetail;
