import { GET_ORDERS, GET_ORDERS_ITEMS } from "../types";

const initialState = {
  orders: [],
  orderItems: [],
};

export default function ordersReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_ORDERS:
      return {
        ...state,
        orders: payload,
      };
    case GET_ORDERS_ITEMS:
      return {
        ...state,
        orderItems: payload,
      };

    default:
      return { ...state };
  }
}
