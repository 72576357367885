import firebase from "../../config/firebase";
import { GET_DISPUTE_REVIEWS, UPDATE_DISPUTE_REVIEW } from "store/types";
import { createNullCache } from "@algolia/cache-common";
import algoliasearch from "algoliasearch";
const client = algoliasearch("AKYAZJGVV9", "f5306d66f87dbf20ad044268210656ed", {
  responsesCache: createNullCache(),
});
export const userIndex = client.initIndex("users");

export const getAllDiputeReviews =
  (item, search, batchSize = 2) =>
  async (dispatch, getState) => {
    await dispatch({ type: "LOADING", payload: true });
    try {
      if (search) {
        userIndex.search(search).then(async (response) => {
          dispatch({ type: "LOADING", payload: false });
          const objectIDs = response.hits.map((hit) => hit.objectID);
          const matchingItems = [];
          console.log(objectIDs, "objectid");
          for (const objectID of objectIDs) {
            const docRef = await firebase
              .firestore()
              .collection("sellerReviews")
              .where("sellerId", "==", objectID)
              .get();
            if (!docRef.empty) {
              docRef.forEach((doc) => {
                const data = doc.data();
                if (data.disputeStatus && data.disputeStatus === "disputed") {
                  matchingItems.push({ id: doc.id, ...data });
                }
              });
            }
          }
          dispatch({
            type: GET_DISPUTE_REVIEWS,
            payload: matchingItems,
            sellerLastDocument: {},
            sellerActive: false,
          });
        });
      } else {
        const itemsRef = await firebase
          .firestore()
          .collection("sellerReviews")
          .where("disputeStatus", "==", "disputed");
        let query = itemsRef.orderBy("createdAt", "desc").limit(batchSize);
        if (item && item.sellerLastDocument) {
          query = query.startAfter(item.sellerLastDocument);
        }
        let isFirstFetch = item === null;
        const unsubscribe = query.onSnapshot(async (snapshot) => {
          const tempData = [];
          for (const doc of snapshot.docs) {
            const itemData = doc.data();
            tempData.push({
              ...itemData,
              id: doc.id,
            });
          }
          const sellerLastDocument =
            snapshot.docs.length > 0
              ? snapshot.docs[snapshot.docs.length - 1]
              : null;
          const newItem = { sellerLastDocument };
          const previousQuestions = getState().reviews.disputeReviews;
          const newItems = tempData.filter((item) => {
            const exists = previousQuestions.some(
              (prevQuestion) => prevQuestion.id === item.id
            );
            return !exists;
          });
          let updatedItems = [];
          if (isFirstFetch) {
            isFirstFetch = false;
            dispatch({
              type: GET_DISPUTE_REVIEWS,
              payload: tempData,
              sellerLastDocument: newItem,
              sellerActive: true,
            });
            dispatch({ type: "LOADING", payload: false });
          } else if (newItems.length > 0) {
            updatedItems = item
              ? [...previousQuestions, ...tempData]
              : tempData;
            dispatch({
              type: GET_DISPUTE_REVIEWS,
              payload: updatedItems,
              sellerLastDocument: newItem,
              sellerActive: true,
            });
            dispatch({ type: "LOADING", payload: false });
          } else {
            updatedItems = item
              ? [...previousQuestions, ...tempData]
              : tempData;
            dispatch({
              type: GET_DISPUTE_REVIEWS,
              payload: updatedItems,
              sellerLastDocument: newItem,
              sellerActive: false,
            });
            dispatch({ type: "LOADING", payload: false });
          }
        });
        return () => unsubscribe();
      }
    } catch (error) {
      console.log(error.message);
      dispatch({ type: "LOADING", payload: false });
    }
  };

//updateDisputeStatus
export const publishDisputeReview = (reviewId, status) => async (dispatch) => {
  const sellerReviews = firebase.firestore().collection("sellerReviews");

  try {
    await sellerReviews.doc(reviewId).update({
      disputeStatus: status,
    });

    dispatch({ type: UPDATE_DISPUTE_REVIEW, payload: reviewId });
  } catch (error) {
    console.error("Error publishing review:", error);
  }
};
