import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";
import { FaEye } from "react-icons/fa";

const ImageModel = ({ isOpen, toggle, selectedItemImages }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const handleIconClick = (image) => {
    setPreviewImage(image);
  };

  const closePreview = () => {
    setPreviewImage(null);
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle} className="border-bottom">
          <h3 className="mb-0">Product Images</h3>
        </ModalHeader>
        <ModalBody>
          {selectedItemImages && selectedItemImages.length > 0 ? (
            <Row>
              {selectedItemImages.map((item, index) => (
                <Col
                  key={index}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  style={{ position: "relative" }}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  <img
                    src={item}
                    width="100%"
                    className="mb-3 border shadow-lg"
                    alt={`Product Image ${index}`}
                  />
                  {hoveredIndex === index && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "rgba(255, 255, 255, 0.7)",
                      }}
                    >
                      <FaEye
                        style={{ fontSize: "24px", cursor: "pointer" }}
                        onClick={() => handleIconClick(item)}
                      />
                    </div>
                  )}
                </Col>
              ))}
            </Row>
          ) : (
            <p className="text-center">No images available.</p>
          )}
        </ModalBody>
      </Modal>
      <Modal isOpen={previewImage !== null} toggle={closePreview}>
        <ModalHeader toggle={closePreview} className="border-bottom">
          <h3 className="mb-0">Image Preview</h3>
        </ModalHeader>
        <ModalBody>
          <img
            src={previewImage}
            width="100%"
            className="mb-3 border shadow-lg"
            alt="Preview"
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default ImageModel;
