import { GET_VENDORS , ADD_VENDOR} from "store/types";
import firebase from "../../config/firebase";

export const getVendors = () => async (dispatch) => {
  await dispatch({ type: "LOADING", payload: true });
  try {
    const querySnapshot = await firebase
      .firestore()
      .collection("invitedVendors")
      .orderBy("createdAt", "desc")
      .get();

    const vendors = querySnapshot.docs.map((doc) => doc.data());

    console.log("vendors: ", vendors);

    dispatch({ type: GET_VENDORS, payload: vendors });
    dispatch({ type: "LOADING", payload: false });

  } catch (error) {
    console.error("Error getting vendors:", error);
    alert("Error getting vendors!");
  }
};

export const addVendor =
  (newVendor , onSuccess=()=>{}) =>
  async (dispatch) => {
    try {
      dispatch({ type: ADD_VENDOR, payload: true });

      await firebase.firestore().collection("invitedVendors").add({
        createdAt: firebase.firestore.Timestamp.now(),
        name: newVendor.name,
        email: newVendor.email,
        organization: newVendor.organization,
        status: newVendor.status
      });
      onSuccess()
      dispatch({ type: ADD_VENDOR, payload: false });
    } catch (error) {
      console.error("Error adding the Vendor:", error);
    }
  };