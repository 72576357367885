import { DASHBOARD_STATS } from "store/types";
import firebase from "../../config/firebase";
export const getDashboardStatsAction = () => async (dispatch) => {
  try {
    const doc = await firebase
      .firestore()
      .collection("dashboardStats")
      .doc("ZivBYPNpbCWEECr6gDry")
      .get();

    const stats = doc.data();
    console.log("stats: ", stats);

    dispatch({ type: DASHBOARD_STATS, payload: stats });
  } catch (error) {
    console.error("Error Getting Stats:", error);
    alert("Error Getting Stats !");
  }
};
