import OnlyHeader from "components/Headers/OnlyHeader";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTickets } from "store/actions/ticketsAction";
import { updateTicketStatus } from "store/actions/ticketsAction";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Media,
  Spinner,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Dropdown,
  Col,
} from "reactstrap";
import { getPlateformCategories } from "store/actions/ticketsAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getSingleTicket } from "store/actions/ticketsAction";
import { updateHistory } from "store/actions/ticketsAction";

const AdminTickets = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { ticketsRequests, ticketsLoading, plateFormCategories } = useSelector(
    (state) => state.ticket
  );
  const [categoryDropdownOpen, setCategoryDropdownOpen] = useState(false);
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [currentCategory, setcurrentCategory] = useState("All");
  const [itemsToShow, setItemsToShow] = useState(10);

  const loadMore = () => {
    setItemsToShow(itemsToShow + 10);
  };
  const loadLess = () => {
    setItemsToShow(10);
  };

  const categoryToggle = () =>
    setCategoryDropdownOpen((prevState) => !prevState);
  const filterToggle = () => setFilterDropdownOpen((prevState) => !prevState);

  const filterTickets = (filter) => {
    if (filter == "") {
      setFilteredTickets(ticketsRequests);
    } else {
      const updatedFiltered = ticketsRequests?.filter(
        (request) => request?.status === filter
      );
      setFilteredTickets(updatedFiltered);
    }
  };

  const handleHistory = async (newHistory, id, timestamp = null) => {
    try {
      const formattedTimestamp = timestamp || new Date().toISOString();
      const historyData = {
        event: newHistory,
        createdAt: formattedTimestamp,
      };
      await dispatch(updateHistory(historyData, id));
      dispatch(getSingleTicket(id));
    } catch (error) {
      console.error("Error handling history:", error);
    }
  };

  const handleUpdateStatus = async (newStatus, id) => {
    const timestamp = new Date().toISOString();
    const existingTicket = ticketsRequests.find((ticket) => ticket.id === id);

    if (existingTicket && existingTicket.status !== newStatus) {
      await dispatch(updateTicketStatus(newStatus, id));
      await handleHistory(`Status changed to ${newStatus}`, id, timestamp);
    }
  };

  useEffect(() => {
    if (ticketsRequests.length > 0) {
      setFilteredTickets(ticketsRequests);
    } else {
      setFilteredTickets([]);
    }
  }, [ticketsRequests]);

  useEffect(() => {
    dispatch(getTickets(currentCategory));
  }, [dispatch, currentCategory]);

  useEffect(() => {
    dispatch(getPlateformCategories());
  }, []);

  return (
    <div>
      {" "}
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center mx-0 mx-lg-3">
                  <h3 className="mb-2 mb-sm-0">Tickets </h3>
                  <div className="">
                    <Dropdown
                      className="mx-2 mx-sm-0 mb-2"
                      size="sm"
                      isOpen={categoryDropdownOpen}
                      toggle={categoryToggle}
                      direction="down"
                    >
                      <DropdownToggle caret color="primary">
                        {currentCategory === "All"
                          ? `${currentCategory} Category`
                          : currentCategory}
                      </DropdownToggle>
                      <DropdownMenu style={{ minWidth: "70px" }}>
                        {plateFormCategories?.map((category, indx) => (
                          <DropdownItem
                            key={indx}
                            onClick={() => setcurrentCategory(category)}
                          >
                            {category}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                    <Dropdown
                      className="mx-2 mx-sm-0"
                      size="sm"
                      isOpen={filterDropdownOpen}
                      toggle={filterToggle}
                      direction="down"
                    >
                      <DropdownToggle caret color="primary">
                        Filter By
                      </DropdownToggle>
                      <DropdownMenu style={{ minWidth: "70px" }}>
                        <DropdownItem onClick={() => filterTickets("")}>
                          All Tickets
                        </DropdownItem>
                        <DropdownItem onClick={() => filterTickets("open")}>
                          Open Tickets
                        </DropdownItem>
                        <DropdownItem onClick={() => filterTickets("close")}>
                          Close Tickets
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
              </CardHeader>
              {ticketsLoading ? (
                <Row className="justify-content-center my-4">
                  <Spinner size="md" />
                </Row>
              ) : (
                <>
                  {filteredTickets?.length > 0 ? (
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Ticket No</th>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Category</th>
                          <th scope="col">Role</th>
                          <th scope="col">Status</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {" "}
                        {filteredTickets
                          .slice(0, itemsToShow)
                          .map((value, index) => {
                            return (
                              <tr key={index}>
                                <th scope="row">
                                  <Media className="align-items-center">
                                    <Media>
                                      <span className="mb-0 text-sm text-info">
                                        #{value?.ticketNumber || "N/A"}
                                      </span>
                                    </Media>
                                  </Media>
                                </th>
                                <td className="text-capitalize">
                                  {value?.issuerName || "N/A"}
                                </td>
                                <td>
                                  {value?.issuerEmail || "N/A"}
                                </td>
                                <td className="text-capitalize">
                                  {value?.category || "N/A"}
                                </td>
                                <td className="text-capitalize">
                                  {value?.role || "N/A"}
                                </td>
                                <td>
                                  <UncontrolledDropdown group size="sm">
                                    <Button
                                      color={`${
                                        value?.status === "close"
                                          ? "secondary"
                                          : "warning"
                                      }`}
                                      size="sm"
                                      className="text-capitalize"
                                    >
                                      <div
                                        color={`${
                                          value?.status === "close"
                                            ? "secondary"
                                            : "warning"
                                        }`}
                                      >
                                        {value.status}
                                      </div>
                                    </Button>
                                    <DropdownToggle
                                      caret
                                      color={`${
                                        value?.status === "close"
                                          ? "secondary"
                                          : "warning"
                                      }`}
                                    />
                                    <DropdownMenu>
                                      <DropdownItem
                                        onClick={() => {
                                          handleUpdateStatus("open", value.id);
                                          handleHistory(value.id);
                                        }}
                                      >
                                        Open
                                      </DropdownItem>

                                      <DropdownItem
                                        onClick={() => {
                                          handleUpdateStatus("close", value.id);
                                          handleHistory(value.id);
                                        }}
                                      >
                                        Close
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </td>
                                <td>
                                  <Button
                                    size="sm"
                                    color="primary"
                                    onClick={() =>
                                      history.push(
                                        `/admin/adminTicket/${value.id}`
                                      )
                                    }
                                  >
                                    Details
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}{" "}
                      </tbody>
                    </Table>
                  ) : (
                    <div className="text-center my-4">No Tickets Exists</div>
                  )}
                </>
              )}
            </Card>
          </div>
        </Row>
        <Row>
          <Col className="my-3">
            <div className="d-flex justify-content-center align-items-center">
              {itemsToShow < ticketsRequests?.length ? (
                <Button onClick={loadMore} size="sm" color="primary">
                  Load More
                </Button>
              ) : itemsToShow > 10 ? (
                <Button onClick={loadLess} size="sm" color="warning">
                  Show Less
                </Button>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AdminTickets;
