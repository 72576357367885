import { GET_ORDERS_ITEMS } from "store/types";
import firebase from "../../config/firebase";
import { GET_ORDERS } from "store/types";
export const getOrdersAction = (customerId) => async (dispatch) => {
  console.log("customerId: ", customerId);
  try {
    const ordersSnapshot = await firebase
      .firestore()
      .collection("orders")
      .where("customerId", "==", customerId)
      .get();

    const ordersArray = [];

    ordersSnapshot.forEach((orderDoc) => {
      const orderData = orderDoc.data();
      ordersArray.push(orderData);
    });

    dispatch({ type: GET_ORDERS, payload: ordersArray });

    return ordersArray.map((order) => order.orderId);
  } catch (error) {
    console.error("Error Getting Orders:", error);
    alert("Error Getting Orders!");
  }
};

export const getOrderItemsAction = (orderId) => async (dispatch) => {
  try {
    const orderItemsArray = [];

    const orderItemsSnapshot = await firebase
      .firestore()
      .collection("orders")
      .doc(orderId)
      .collection("orderItems")
      .get();

    orderItemsSnapshot.forEach((orderItemDoc) => {
      const orderItemData = orderItemDoc.data();
      orderItemsArray.push({
        orderItemData,
      });
    });

    console.log("orderItemsArray: ", orderItemsArray);

    dispatch({ type: GET_ORDERS_ITEMS, payload: orderItemsArray });
  } catch (error) {
    console.error("Error Getting Order Items:", error);
    alert("Error Getting Order Items!");
  }
};
