import { GET_DISPUTE_REVIEWS } from "../types";

const initialState = {
  disputeReviews: [],
  sellerLoader: false,
  sellerActive: true,
  sellerLastDocument: {},
};

export default function reviewsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DISPUTE_REVIEWS:
      return {
        ...state,
        disputeReviews: action.payload,
        sellerActive: action.sellerActive,
        sellerLastDocument: action.sellerLastDocument,
      };
      case "LOADING":
      return {
        ...state,
        sellerLoader: action.payload,
      };
    default:
      return { ...state };
  }
}
