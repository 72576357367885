import { useEffect, useRef, useState } from "react";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Col,
  Label,
  Input,
  Form,
  Spinner,
  CardBody,
} from "reactstrap";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from "draft-convert";
import draftToHtml from "draftjs-to-html";
import { useDispatch, useSelector } from "react-redux";
import htmlToDraft from "html-to-draftjs";
import { useHistory, useParams } from "react-router-dom";
import { addBlog } from "store/actions/blogsAction";
import { useLocation } from "react-router-dom";
import OnlyHeader from "components/Headers/OnlyHeader";
import { updateBlog } from "store/actions/blogsAction";
import { getSingleBlog } from "store/actions/blogsAction";

const AddBlog = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const { editBlogLoader, mediaUrl, singleBlog } = useSelector(
    (state) => state.blogs
  );
  const [blogData, setBlogData] = useState({
    title: "",
    content: "",
  });

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [convertedContent, setConvertedContent] = useState(null);
  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };

  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  };

  console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  const handleBlogDataChange = (e) => {
    e.preventDefault();
    if (e.target.name == "featuredImgURL" || e.target.name === "getMediaURL") {
      setBlogData({
        ...blogData,
        [e.target.name]: e.target.files[0],
      });
    } else {
      setBlogData({
        ...blogData,
        [e.target.name]: e.target.value,
      });
    }
  };

  useEffect(() => {
    if (mediaUrl) {
      setBlogData({
        ...blogData,
        mediaFiles: [...blogData.mediaFiles, mediaUrl],
      });
    }
  }, [mediaUrl]);
  useEffect(() => {
    dispatch(getSingleBlog(id));
  }, []);
  useEffect(() => {
    if (singleBlog?.editor) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(htmlToDraft(singleBlog?.editor))
        )
      );

      setBlogData({
        title: singleBlog?.title,
      });
    }
  }, [singleBlog]);

  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row>
          <Card>
            <CardBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  const temp = {
                    ...blogData,
                    content: draftToHtml(
                      convertToRaw(editorState.getCurrentContent())
                    ),
                  };

                  const paylaod = {
                    title: temp.title,
                    content: temp.content,
                  };

                  dispatch(
                    updateBlog(id, paylaod, () => {
                      history.push("/admin/Blogs");
                    })
                  );
                }}
              >
                <Row>
                  <Col md="6" className="my-2">
                    <Label className="mb-0 my-auto ">Title</Label>
                    <Input
                      name="title"
                      value={blogData.title}
                      required
                      onChange={(e) => {
                        handleBlogDataChange(e);
                      }}
                    />
                  </Col>

                  <Col md={12} className="mt-3">
                    <Editor
                      editorState={editorState}
                      editorStyle={{
                        border: "2px solid #C0C0C0",
                      }}
                      toolbar={{
                        fontFamily: {
                          options: [
                            "Arial",
                            "Georgia",
                            "Impact",
                            "Tahoma",
                            "Times New Roman",
                            "Verdana",
                            "Barlow",
                          ],
                        },
                      }}
                      onEditorStateChange={handleEditorChange}
                    />
                  </Col>
                </Row>
                <div className="d-flex justify-content-end align-items-center">
                  <Button type="submit" color="primary" className="mt-3">
                    {editBlogLoader ? (
                      <Spinner
                        className="d-flex mx-auto align-items-center justify-content-center overflow-hidden"
                        size="sm"
                        color="white"
                      />
                    ) : (
                      "SAVE CHANGES"
                    )}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Row>
      </Container>
    </>
  );
};
export default AddBlog;
