import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import { getVendors } from "store/actions/InviteVendorAction";
import { addVendor } from "store/actions/InviteVendorAction";

const InviteVendorModal = ({ isOpen, toggle, data }) => {
  const dispatch = useDispatch();

  const [vendorData, setVendorData] = useState({
    name: "",
    email: "",
    organization: "",
    status: "pending",
  });

  const [addingVendor, setAddingVendor] = useState(false);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setVendorData({ ...vendorData, [id]: value });
  };

  const validateForm = () => {
    if (!vendorData.name || !vendorData.email || !vendorData.organization) {
      alert("Please fill out all fields.");
      return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(vendorData.email)) {
      alert("Please enter a valid email address.");
      return false;
    }

    return true;
  };

  const handleInviteVendor = async () => {
    try {
      if (!validateForm()) {
        return;
      }

      setAddingVendor(true);
      await dispatch(addVendor(vendorData, () => {
        dispatch(getVendors());
        toggle();
        setVendorData({
          name: "",
          email: "",
          organization: "",
          status: "pending",
        });
      }));
    } catch (error) {
      console.error("Error inviting the vendor:", error);
    } finally {
      setAddingVendor(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Invite Vendor</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input
              type="text"
              id="name"
              value={vendorData.name}
              required
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              type="email"
              id="email"
              value={vendorData.email}
              required
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="organization">Organization</Label>
            <Input
              type="text"
              id="organization"
              value={vendorData.organization}
              required
              onChange={handleInputChange}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
      <Button color="danger" onClick={toggle}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleInviteVendor} disabled={addingVendor}>
          {addingVendor ? <Spinner size="sm" color="light" /> : "Send Invitation"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default InviteVendorModal;
