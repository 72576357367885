import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Row,
  Modal,
  ModalBody,
  Col,
  ModalFooter,
} from "reactstrap";

function Tags({ isOpen, toggle, data }) {
  const [creds, setCreds] = useState({
    title: "",
    embededUrl: "",
  });

  const handleInputChange = (e) => {
    setCreds((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your form submission logic here
    console.log("Form submitted with credentials: ", creds);
  };

  return (
    <Modal toggle={toggle} isOpen={isOpen}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {data ? "Edit Tags " : "Add Tags"}
        </h5>
        <button
          aria-label="Close"
          className="close"
          type="button"
          onClick={toggle}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <ModalBody>
        <Row>
          <Form role="form" onSubmit={handleSubmit} className="w-100 px-4">
            <Row>
              <Col md={12}>
                <Input
                  placeholder="Product Categories"
                  type="text"
                  name="embededUrl"
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={12}>
                <Input
                  placeholder="Regions Covered"
                  type="text"
                  name="embededUrl"
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={12}>
                <Input
                  placeholder="Currency"
                  type="text"
                  name="embededUrl"
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={12}>
                <Input
                  placeholder="Sales to date"
                  type="text"
                  name="embededUrl"
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={12}>
                <Input
                  placeholder="Revenue to date"
                  type="text"
                  name="embededUrl"
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
          </Form>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" type="button" onClick={toggle}>
          Close
        </Button>
        <Button color="primary" type="button">
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default Tags;
