import { ADD_VENDOR, GET_VENDORS } from "../types";

const initialState = {
  vendors: [],
  addVendor: false,
  loading:false
};

export default function inviteVendorReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_VENDORS:
      return {
        ...state,
        vendors: payload,
      };
      case ADD_VENDOR:
      return {
        ...state,
        addVendor: payload,
      };
      case "LOADING":
        return {
          ...state,
          loading: payload,
        };
    default:
      return { ...state };
  }
}