const initialState = {
  organzitionalRequests: [],
};

export default function adminReducers(state = initialState, { type, payload }) {
  switch (type) {
    case "ORGANIZATION":
      return {
        ...state,
        organzitionalRequests: payload,
      };

      case "GET_REQUEST_LOADING":
        return {
          ...state,
          loading: payload
        }

    default:
      return { ...state };
  }
}
