import {
  ADD_CATEGORY_LOADER,
  GET_CATEGORIES,
  DELETE_CATEGORY_LOADER,
  UPDATE_CATEGORY_LOADER,
} from "../types";

const initialState = {
  allCategories: [],
  addCategoryLoader: false,
  deleteCategoryLoader: false,
  updateCategoryLoader: false,
};

export default function categoryReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case ADD_CATEGORY_LOADER:
      return {
        ...state,
        addCategoryLoader: payload,
      };
    case UPDATE_CATEGORY_LOADER:
      return {
        ...state,
        updateCategoryLoader: payload,
      };

    case DELETE_CATEGORY_LOADER:
      return {
        ...state,
        deleteBlogLoader: payload,
      };
    case GET_CATEGORIES:
      return {
        ...state,
        allCategories: payload,
      };

    default:
      return { ...state };
  }
}
