const initialState = {
  ticketsRequests: [],
  singleTicket: {},
  plateFormCategories: [],
  ticketsLoading: false,
};

const ticketReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_TICKETS":
      return {
        ...state,
        ticketsRequests: payload,
      };
    case "GET_SINGLE_TICKET":
      return {
        ...state,
        singleTicket: {
          ID: payload.ID,
          ticketData: payload.ticketData,
        },
      };
    case "GET_TICKETS_LOADING":
      return {
        ...state,
        ticketsLoading: payload,
      };
    case "PLATEFORM_TICKETS":
      return {
        ...state,
        plateFormCategories: payload,
      };
    case "UPDATE_TICKET_STATUS":
      const updatedTickets = state.ticketsRequests.map((request) => {
        if (request.id === payload.ID) {
          return {
            ...request,
            status: payload.status,
          };
        }
        return request;
      });

      return {
        ...state,
        ticketsRequests: updatedTickets,
      };
    default:
      return state;
  }
};

export default ticketReducer;
