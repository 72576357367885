import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { updateCategory } from "store/actions/categoriesAction";
import { addCategory } from "store/actions/categoriesAction";
import { addVideo } from "store/actions/youtubeVideosAction";

function AddCategoryModel({ isOpen, toggle, data }) {
  const dispatch = useDispatch();
  const { addCategoryLoader, updateCategoryLoader } = useSelector(
    (state) => state.categories
  );
  const [creds, setCreds] = useState({
    videoTitle: "",
    videoUrl: "",
  });

  const handleInputChange = (e) => {
    setCreds((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (data?.id) {
      dispatch(
        updateCategory(data?.id, creds, () => {
          setCreds({
            name: "",
            productsSold: "",
          });
          alert("Category Updated Successfully");
          toggle();
        })
      );
    } else {
      dispatch(
        addCategory(creds, () => {
          setCreds({
            name: "",
            productsSold: "",
          });
          alert("Category Created Successfully");
          toggle();
        })
      );
    }
  };
  useEffect(() => {
    if (data?.id) {
      setCreds({
        name: data?.name,
        productsSold: data?.productsSold,
      });
    }
  }, [data]);
  return (
    <Modal toggle={toggle} isOpen={isOpen}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Add New Category
        </h5>
        <button
          aria-label="Close"
          className="close"
          type="button"
          onClick={toggle}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <ModalBody>
        <Row>
          <Form role="form" onSubmit={handleSubmit} className="w-100 px-4">
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <Input
                  placeholder="Title"
                  required
                  className="w-100"
                  type="Category name"
                  value={creds.name}
                  name="name"
                  onChange={handleInputChange}
                  autoComplete="new-email"
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <Input
                  placeholder="Products Sold"
                  required
                  type="number"
                  value={creds.productsSold}
                  name="productsSold"
                  onChange={handleInputChange}
                />
              </InputGroup>
            </FormGroup>
            <Row className="justify-content-end">
              <Button color="primary" type="submit" className="mx-2">
                {addCategoryLoader || updateCategoryLoader ? (
                  <Spinner size="sm" />
                ) : (
                  "Save Changes"
                )}
              </Button>
              <Button
                color="danger"
                type="button"
                onClick={toggle}
                className="mx-2"
              >
                Close
              </Button>
            </Row>
          </Form>
        </Row>
      </ModalBody>
    </Modal>
  );
}

export default AddCategoryModel;
