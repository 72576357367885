import React from "react";
import { Card, Row, Col, CardBody, CardText, Badge } from "reactstrap";

const OrderCard = ({ data }) => {
  const { price, quantity, trackingNo, createdAt } = data?.orderItemData;

  return (
    <Card className="mb-4 border-0 shadow-sm">
      <CardBody>
        <Row className="align-items-center">
          <Col xs={12} md={4} className="mb-3 mb-md-0">
            <div>
              <CardText className="text-muted small">
                <strong>Quantity:</strong> {quantity}
              </CardText>
            </div>
          </Col>
          <Col xs={12} md={4} className="mb-3 mb-md-0">
            <div>
              <CardText className="text-muted small">
                <strong>Price:</strong> ${price}
              </CardText>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="text-md-right">
              <div className="text-muted small">Tracking ID:</div>
              <Badge color="success">{trackingNo}</Badge>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default OrderCard;
