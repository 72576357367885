import { GET_CATEGORIES } from "store/types";
import firebase from "../../config/firebase";
import { ADD_CATEGORY_LOADER } from "store/types";
import { DELETE_CATEGORY_LOADER, UPDATE_CATEGORY_LOADER } from "store/types";
export const getAllCategories = () => async (dispatch) => {
  try {
    firebase
      .firestore()
      .collection("categories")
      .onSnapshot((querySnapshot) => {
        let categories = [];
        for (let doc of querySnapshot.docs) {
          categories.push({
            id: doc.id,
            ...doc.data(),
          });
        }

        dispatch({ type: GET_CATEGORIES, payload: categories });
      });
  } catch (error) {
    alert("Not Found ! Any Categories");
  }
};

export const deleteCategory =
  (catId, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({ type: DELETE_CATEGORY_LOADER, payload: true });

      const catRef = firebase.firestore().collection("categories").doc(catId);
      await catRef.delete();
      onSuccess();
      dispatch({ type: DELETE_CATEGORY_LOADER, payload: false });
    } catch (error) {
      console.error("Error deleting the category:", error);
    }
  };

export const addCategory =
  (newCategory, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({ type: ADD_CATEGORY_LOADER, payload: true });

      await firebase.firestore().collection("categories").add({
        name: newCategory.name,
        productsSold: newCategory.productsSold,
        createdAt: firebase.firestore.Timestamp.now(),
      });
      onSuccess();
      dispatch({ type: ADD_CATEGORY_LOADER, payload: false });
    } catch (error) {
      console.error("Error adding the Category:", error);
    }
  };

export const updateCategory =
  (Id, updatedData, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_CATEGORY_LOADER,
        payload: true,
      });
      await firebase.firestore().collection("categories").doc(Id).update({
        name: updatedData?.name,
        productsSold: updatedData?.productsSold,
        updatedAt: firebase.firestore.Timestamp.now(),
      });
      dispatch({
        type: UPDATE_CATEGORY_LOADER,
        payload: false,
      });
      onSuccess();
    } catch (error) {
      dispatch({
        type: UPDATE_CATEGORY_LOADER,
        payload: false,
      });
      console.error("Error updating the category:", error);
    }
  };
