import React, { useEffect, useState } from "react";
import OnlyHeader from "components/Headers/OnlyHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  Container,
  Media,
  Row,
  Table,
  Spinner,
} from "reactstrap";
import {
  getOrganizationRequests,
  updateRequestStatus,
  updateWeekRequestStatus,
} from "store/actions/adminActions";
import TabSelection from "components/Review/TabSelection";

const Requests = () => {
  const dispatch = useDispatch();
  const { organzitionalRequests, loading } = useSelector(
    (state) => state.admin
  );
  const RequestSections = ["Organization", "Weekend"];

  const [reviewSection, setReviewSection] = useState("Organization");

  useEffect(() => {
    dispatch(getOrganizationRequests());
  }, []);

  const handleButtonClick = async (item, status) => {
    await dispatch(updateRequestStatus(item, status));
    console.log("item", item);
  };
  const handleWeekButtonClick = async (items, status) => {
    await dispatch(updateWeekRequestStatus(items, status));
    console.log("items", items);
  };

  const filteredRequests = organzitionalRequests.filter((request) => {
    if (reviewSection === "Organization") {
      return request?.type === "updateOrganization";
    } else if (reviewSection === "Weekend") {
      return request?.type === "updateWeekends";
    }
    return false;
  });

  // const filteredRequests2 = updateWeekRequestStatus.filter((request) => {
  //   if (reviewSection === "Weekend Request") {
  //     return request?.type === "updateWeekends";
  //   }
  //   return false;
  // });

  return (
    <div>
      <OnlyHeader />
      <TabSelection
        options={RequestSections}
        onChange={(value) => setReviewSection(value)}
      />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            {reviewSection === "Organization" ? (
              <Card className="shadow">
                <CardHeader className="border-0 ">
                  <div className="d-flex justify-content-between align-items-center mx-0 mx-lg-3">
                    <h3 className="mb-0">Organization Requests</h3>
                  </div>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Current Org Name</th>
                      <th scope="col">Requested Org Name</th>
                      <th scope="col">Reason</th>
                      <th scope="col">Status</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  {loading ? (
                    <tbody>
                      <tr>
                        <td colSpan="5" className="text-center">
                          <Spinner size="lg" />
                        </td>
                      </tr>
                    </tbody>
                  ) : filteredRequests.length === 0 ? (
                    <tbody>
                      <tr className="mt-2">
                        <td>No Request available.</td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {filteredRequests?.map((request, index) => {
                        return (
                          <tr key={index}>
                            <th scope="row">
                              <Media className="align-items-center">
                                <Media>
                                  <span className="mb-0 text-sm text-capitalize">
                                    {request?.sellerName}
                                  </span>
                                </Media>
                              </Media>
                            </th>
                            <td className="text-capitalize">
                              {request?.currentOrgName}
                            </td>
                            <td className="text-capitalize">
                              {request?.requestedOrgName}
                            </td>
                            <td className="text-capitalize">
                              {request?.reason}
                            </td>
                            <td className="text-capitalize">
                              {request?.status}
                            </td>
                            <td>
                              <Button
                                color="success"
                                size="sm"
                                onClick={() =>
                                  handleButtonClick(request, "accepted")
                                }
                                disabled={request?.status !== "pending"}
                              >
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                              </Button>
                              <Button
                                color="danger"
                                size="sm"
                                onClick={() =>
                                  handleButtonClick(request, "rejected")
                                }
                                disabled={request?.status !== "pending"}
                              >
                                <i
                                  className="fa fa-times"
                                  aria-hidden="true"
                                ></i>
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </Table>
              </Card>
            ) : (
              <Card className="shadow">
                <CardHeader className="border-0 ">
                  <div className="d-flex justify-content-between align-items-center mx-0 mx-lg-3">
                    <h3 className="mb-0">Weekend Requests</h3>
                  </div>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Current Week Names</th>
                      <th scope="col">Requested Week Names</th>
                      <th scope="col">Reason</th>
                      <th scope="col">Status</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  {loading ? (
                    <tbody>
                      <tr>
                        <td colSpan="5" className="text-center">
                          <Spinner size="lg" />
                        </td>
                      </tr>
                    </tbody>
                  ) : filteredRequests.length === 0 ? (
                    <tbody>
                      <tr className="mt-2">
                        <td>No Request available.</td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {filteredRequests?.map((request, index) => {
                        return (
                          <tr key={index}>
                            <th scope="row">
                              <Media className="align-items-center">
                                <Media>
                                  <span className="mb-0 text-sm text-capitalize">
                                    {request?.sellerName}
                                  </span>
                                </Media>
                              </Media>
                            </th>
                            <td className="text-capitalize">
                              {request.currentWeekendName
                                ? request.currentWeekendName.join("/")
                                : "N/A"}
                            </td>
                            <td className="text-capitalize">
                              {request.requestedWeekName
                                ? request.requestedWeekName.join("/")
                                : "N/A"}
                            </td>
                            <td className="text-capitalize">
                              {request?.reason}
                            </td>
                            <td className="text-capitalize">
                              {request?.status}
                            </td>
                            <td>
                              <Button
                                color="success"
                                size="sm"
                                onClick={() =>
                                  handleWeekButtonClick(request, "accepted")
                                }
                                disabled={request?.status !== "pending"}
                              >
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                              </Button>
                              <Button
                                color="danger"
                                size="sm"
                                onClick={() =>
                                  handleWeekButtonClick(request, "rejected")
                                }
                                disabled={request?.status !== "pending"}
                              >
                                <i
                                  className="fa fa-times"
                                  aria-hidden="true"
                                ></i>
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </Table>
              </Card>
            )}
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Requests;
