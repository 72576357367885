import { GET_SINGLE_BLOG, GET_MORE_BLOGS } from "store/types";
import firebase from "../../config/firebase";

import { GET_BUYERS } from "store/types";
import { createNullCache } from "@algolia/cache-common";
import algoliasearch from "algoliasearch";
const client = algoliasearch("AKYAZJGVV9", "f5306d66f87dbf20ad044268210656ed", {
  responsesCache: createNullCache(),
});
export const itemsIndex = client.initIndex("users");
export const productIndex = client.initIndex("items");

export const getBuyers =
  (item, search, batchSize = 2) =>
  async (dispatch, getState) => {
    await dispatch({ type: "LOADING", payload: true });
    try {
      if (search) {
        itemsIndex.search(search).then(async (response) => {
          dispatch({ type: "LOADING", payload: false });
          const objectIDs = response.hits.map((hit) => hit.objectID);
          const matchingItems = [];
          for (const objectID of objectIDs) {
            const docRef = await firebase
              .firestore()
              .collection("users")
              .doc(objectID);
            const docSnapshot = await docRef.get();
            if (docSnapshot.exists) {
              const itemData = { id: docSnapshot.id, ...docSnapshot.data() };
              if (itemData.role === "buyer") {
                matchingItems.push(itemData);
              }
            }
          }
          dispatch({
            type: GET_BUYERS,
            payload: matchingItems,
            lastDocument: {},
            active: false,
          });
        });
      } else {
        const itemsRef = await firebase
          .firestore()
          .collection("users")
          .where("role", "==", "buyer");
        let query = itemsRef.orderBy("createdAt", "desc").limit(batchSize);
        if (item && item.lastDocument) {
          query = query.startAfter(item.lastDocument);
        }
        let isFirstFetch = item === null;
        const unsubscribe = query.onSnapshot(async (snapshot) => {
          const tempData = [];
          for (const doc of snapshot.docs) {
            const itemData = doc.data();
            tempData.push({
              ...itemData,
              id: doc.id,
            });
          }
          const lastDocument =
            snapshot.docs.length > 0
              ? snapshot.docs[snapshot.docs.length - 1]
              : null;
          const newItem = { lastDocument };
          const previousBuyers = getState().buyers.allBuyers;
          const newItems = tempData.filter((item) => {
            const exists = previousBuyers.some(
              (prevQuestion) => prevQuestion.id === item.id
            );
            return !exists;
          });
          let updatedItems = [];
          if (isFirstFetch) {
            isFirstFetch = false;
            dispatch({
              type: GET_BUYERS,
              payload: tempData,
              lastDocument: newItem,
              active: true,
            });
            dispatch({ type: "LOADING", payload: false });
          } else if (newItems.length > 0) {
            updatedItems = item ? [...previousBuyers, ...tempData] : tempData;
            dispatch({
              type: GET_BUYERS,
              payload: updatedItems,
              lastDocument: newItem,
              active: true,
            });
            dispatch({ type: "LOADING", payload: false });
          } else {
            updatedItems = item ? [...previousBuyers, ...tempData] : tempData;
            dispatch({
              type: GET_BUYERS,
              payload: updatedItems,
              lastDocument: newItem,
              active: false,
            });
            dispatch({ type: "LOADING", payload: false });
          }
        });
        return () => unsubscribe();
      }
    } catch (error) {
      console.log(error.message);
      dispatch({ type: "LOADING", payload: false });
    }
  };

// export const getBuyers = () => async (dispatch) => {
//   try {
//     firebase
//       .firestore()
//       .collection("users")

//       .where("role", "==", "buyer")
//       .onSnapshot((querySnapshot) => {
//         let buyers = [];
//         for (let doc of querySnapshot.docs) {
//           buyers.push({
//             id: doc.id,
//             ...doc.data(),
//           });
//         }

//         dispatch({ type: GET_BUYERS, payload: buyers });
//       });
//   } catch (error) {
//     alert("Not found any Buyers !!!");
//   }
// };
