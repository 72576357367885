import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Media,
  Row,
  Spinner,
  Table,
} from "reactstrap";
// core components

import { DotPulse } from "@uiball/loaders";
import OnlyHeader from "components/Headers/OnlyHeader.js";
import debounce from "lodash/debounce";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getTeamMembers } from "store/actions/SellersAction";
import { MdArrowBackIosNew } from "react-icons/md";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const TeamMembers = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { teamMembers, active, lastDocument, loader } = useSelector(
    (state) => state.sellers
  );
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [lastDocuments, setLastDocuments] = useState();

  const handleSearch = debounce(async (e) => {
    try {
      await dispatch(getTeamMembers(null, id, e.target.value));
      setIsLoading(false);
    } catch (error) {
      console.error("An error occurred:", error);
      setIsLoading(false);
    }
  }, 3000);

  const handleLoadMore = () => {
    setSearch("");
    dispatch(getTeamMembers(lastDocuments, id, ""));
  };
  useEffect(() => {
    setLastDocuments(lastDocument);
  }, [lastDocument]);

  useEffect(() => {
    dispatch(getTeamMembers(null, id, ""));
  }, [id]);
  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 ">
                <div className="d-flex justify-content-between align-items-center mx-0 mx-lg-3">
                  <h3 className="mb-0">  <MdArrowBackIosNew
                          className="mx-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => history.goBack()}
                        />Team Members</h3>
                  <div className="w-50">
                    <div className="position-relative">
                      <Input
                        className="w-50 float-right mt-1"
                        type="text"
                        placeholder="Search"
                        onChange={(e) => {
                          setIsLoading(true);
                          handleSearch(e);
                        }}
                      />
                      {isLoading ? (
                        <span
                          style={{
                            position: "absolute",
                            top: "20px",
                            right: "10px",
                          }}
                        >
                          <DotPulse size={25} speed={1.3} color="black" />
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Country</th>
                    <th scope="col">Role</th>
                    <th scope="col" />
                  </tr>
                </thead>
                {!loader ? (
                  <tbody>
                    {teamMembers &&
                      teamMembers?.map((team, index) => (
                        <tr key={index}>
                          <th scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {team?.username}
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td>{team?.email}</td>
                          <td>{team?.country?.label}</td>
                          <td className="text-capitalize">{team?.role}</td>
                        </tr>
                      ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="5" className="text-center">
                        <Spinner size="md" />
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
            </Card>
            <div className="d-flex justify-content-center align-items-center mt-3">
              {loader == false && teamMembers.length > 0 ? (
                <>
                  <p className="text-center">
                    {active == false ? "No more items" : ""}
                  </p>
                  <Button
                    onClick={handleLoadMore}
                    className="bg-primary mx-3 text-secondary  mb-2 btn-sm"
                    disabled={active ? false : true}
                  >
                    Load more
                  </Button>
                </>
              ) : (
                "Team member not found !"
              )}
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default TeamMembers;
