import { GET_VIDEOS } from "store/types";
import firebase from "../../config/firebase";
import { ADD_VIDEO_LOADER } from "store/types";
import { DELETE_VIDEO_LOADER } from "store/types";
import { GET_MORE_VIDEOS, GET_MORE_VIDEOS_LOADER } from "store/types";
export const getAllVideos = () => async (dispatch) => {
  try {
    firebase
      .firestore()
      .collection("youtubeVideos")
      .orderBy("createdAt", "desc")
      .limit(10)
      .onSnapshot((querySnapshot) => {
        let youtubeVideos = [];
        for (let doc of querySnapshot.docs) {
          youtubeVideos.push({
            id: doc.id,
            ...doc.data(),
          });
        }

        dispatch({ type: GET_VIDEOS, payload: youtubeVideos });
      });
  } catch (error) {
    alert("Not Found ! Any youtube videos");
  }
};

export const getMoreVideos = (item) => async (dispatch) => {
  console.log(item);
  try {
    dispatch({ type: GET_MORE_VIDEOS_LOADER, payload: true });

    firebase
      .firestore()
      .collection("youtubeVideos")
      .orderBy("createdAt", "desc")
      .limit(10)
      .startAfter(item.createdAt)
      .onSnapshot((querySnapshot) => {
        let youtubeVideos = [];
        for (let doc of querySnapshot.docs) {
          youtubeVideos.push({
            id: doc.id,
            ...doc.data(),
          });
        }

        dispatch({ type: GET_MORE_VIDEOS, payload: youtubeVideos });
        dispatch({ type: GET_MORE_VIDEOS_LOADER, payload: false });
      });
  } catch (error) {
    alert("Not found ! more youtube videos");
    dispatch({ type: GET_MORE_VIDEOS_LOADER, payload: false });
  }
};

export const deleteVideo =
  (videoId, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({ type: DELETE_VIDEO_LOADER, payload: true });

      const videoRef = firebase
        .firestore()
        .collection("youtubeVideos")
        .doc(videoId);
      await videoRef.delete();
      onSuccess();
      dispatch({ type: DELETE_VIDEO_LOADER, payload: false });
    } catch (error) {
      console.error("Error deleting the blog:", error);
    }
  };

export const addVideo =
  (newVideo, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({ type: ADD_VIDEO_LOADER, payload: true });

      await firebase.firestore().collection("youtubeVideos").add({
        videoUrl: newVideo.videoUrl,
        videoTitle: newVideo.videoTitle,
        createdAt: firebase.firestore.Timestamp.now(),
      });

      onSuccess();
      dispatch({ type: ADD_VIDEO_LOADER, payload: false });
    } catch (error) {
      console.error("Error adding/updating the video:", error);
    }
  };

export const updateVideo =
  (videoId, updatedData, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      await firebase
        .firestore()
        .collection("youtubeVideos")
        .doc(videoId)
        .update({
          videoUrl: updatedData?.videoUrl,
          videoTitle: updatedData?.videoTitle,
          updatedAt: firebase.firestore.Timestamp.now(),
        });

      onSuccess();
    } catch (error) {
      console.error("Error updating the video:", error);
    }
  };
