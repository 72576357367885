import { ADD_DISCORD_LOADER, GET_DISCORD } from "../types";

const initialState = {
  discordLink: "",
  addDiscordLoader: false,
};

export default function discordReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case ADD_DISCORD_LOADER:
      return {
        ...state,
        addDiscordLoader: payload,
      };

    case GET_DISCORD:
      return {
        ...state,
        discordLink: payload,
      };

    default:
      return { ...state };
  }
}
