import {
  Card,
  CardHeader,
  Media,
  Table,
  Container,
  Row,
  Button,
  Input,
  Spinner,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

import OnlyHeader from "components/Headers/OnlyHeader.js";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Tags from "components/PopupModels/Tags";
import { useDispatch, useSelector } from "react-redux";
import { getAllSellers } from "store/actions/SellersAction";
import debounce from "lodash/debounce";
import { DotPulse } from "@uiball/loaders";

const Seller = () => {
  const { allSellers, loader, active, lastDocument } = useSelector(
    (state) => state.sellers
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editData, setEditData] = useState("");
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [lastDocuments, setLastDocuments] = useState();

  const dispatch = useDispatch();

  const handleSearch = debounce(async (e) => {
    try {
      await dispatch(getAllSellers(null, e.target.value));
      setIsLoading(false);
    } catch (error) {
      console.error("An error occurred:", error);
      setIsLoading(false);
    }
  }, 3000);

  const handleLoadMore = () => {
    setSearch("");
    dispatch(getAllSellers(lastDocuments, ""));
  };
  useEffect(() => {
    setLastDocuments(lastDocument);
  }, [lastDocument]);

  useEffect(() => {
    dispatch(getAllSellers(null));
  }, []);

  return (
    <>
      <OnlyHeader />

      <Tags
        isOpen={isModalOpen}
        data={editData}
        toggle={() => setIsModalOpen(!isModalOpen)}
      />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 ">
                <div className="d-flex justify-content-between align-items-center mx-0 mx-lg-3">
                  <h3 className="mb-0">Sellers</h3>
                  <div className="w-50">
                    <div className="position-relative">
                      <Input
                        className="w-50 float-right mt-1"
                        type="text"
                        placeholder="Search"
                        onChange={(e) => {
                          setIsLoading(true);
                          handleSearch(e);
                        }}
                      />
                      {isLoading ? (
                        <span
                          style={{
                            position: "absolute",
                            top: "20px",
                            right: "10px",
                          }}
                        >
                          <DotPulse size={25} speed={1.3} color="black" />
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Org Name</th>
                    <th scope="col">Actions</th>

                    <th scope="col" />
                  </tr>
                </thead>
                {allSellers.length > 0 ? (
                  <tbody>
                    {allSellers?.map((seller, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {seller?.username}
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td>{seller?.email}</td>
                          <td>Nextpak</td>
                          <td>
                            <div className="">
                              <div className="d-flex me-4">
                                <Link
                                  to={`/admin/seller/productDetails/${seller?.id}`}
                                >
                                  <Button color="primary" size="sm">
                                    View Product
                                  </Button>
                                </Link>
                                <Link
                                  to={`/admin/seller/orderDetails/${seller?.id}`}
                                  className="ml-2"
                                >
                                  <Button color="primary" size="sm">
                                    View Order
                                  </Button>
                                </Link>

                                <Button
                                  color="primary"
                                  size="sm"
                                  className="ml-2"
                                  onClick={() => {
                                    setIsModalOpen(true);
                                    setEditData("");
                                  }}
                                >
                                  Tags
                                </Button>
                              </div>
                              <div className="mt-2 d-flex me-2">
                                <Link
                                  to={`/admin/seller/distribution/${seller?.id}`}
                                >
                                  <Button color="primary" size="sm">
                                    View Distributors
                                  </Button>
                                </Link>
                                <Link
                                  to={`/admin/seller/teamMembers/${seller?.id}`}
                                >
                                  <Button color="primary ml-2" size="sm">
                                    View Team Members
                                  </Button>
                                </Link>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="4" className="text-center">
                        <Spinner size="lg" />
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>

              {/* <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        2 <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        3
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter> */}
            </Card>
            <div className="d-flex justify-content-center align-items-center mt-3">
              {loader == false ? (
                <>
                  <p className="text-center">
                    {active == false ? "No more items" : ""}
                  </p>
                  <Button
                    onClick={handleLoadMore}
                    className="bg-primary mx-3 text-secondary  mb-2 btn-sm"
                    disabled={active ? false : true}
                  >
                    Load more
                  </Button>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Seller;
