import {
  Badge,
  Card,
  CardHeader,
  Media,
  Table,
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Spinner,
} from "reactstrap";
// core components
import OnlyHeader from "components/Headers/OnlyHeader.js";
import { CircleFlag } from "react-circle-flags";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";
import { DotPulse } from "@uiball/loaders";
import { getBuyers } from "store/actions/buyersAction";
let countryFlag = ["ca", "us", "bs", "ai", "gd", "as", "vc", "pk", "tr", "jo"];

const Buyers = () => {
  const dispatch = useDispatch();
  const { allBuyers, loader, active, lastDocument } = useSelector(
    (state) => state.buyers
  );

  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [lastDocuments, setLastDocuments] = useState();

  const handleSearch = debounce(async (e) => {
    try {
      await dispatch(getBuyers(null, e.target.value));
      setIsLoading(false);
    } catch (error) {
      console.error("An error occurred:", error);
      setIsLoading(false);
    }
  }, 3000);

  const handleLoadMore = () => {
    setSearch("");
    dispatch(getBuyers(lastDocuments, ""));
  };
  useEffect(() => {
    setLastDocuments(lastDocument);
  }, [lastDocument]);
  
  useEffect(() => {
    dispatch(getBuyers());
  }, []);

  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 ">
                <div className="d-lg-flex d-md-flex d-sm-flex justify-content-between align-items-center mx-0 mx-lg-3">
                  <h3 className="mb-0">Buyers</h3>
                  <div className="w-30">
                    <div style={{ maxWidth: "200px" }}>
                      <InputGroup className="input-group-alternative ml-0 ml-lg-4 my-3 my-lg-0 w-100">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Search"
                          type="text"
                          onChange={(e) => {
                            handleSearch(e);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    {/* <th scope="col">Country</th>
                    <th scope="col">Status</th> */}
                    <th scope="col">Actions</th>

                    <th scope="col" />
                  </tr>
                </thead>
                {loader ? (
                  <tbody>
                    <tr>
                      <td colSpan="5" className="text-center">
                        <Spinner size="lg" />
                      </td>
                    </tr>
                  </tbody>
                ) : allBuyers.length === 0 ? (
                  <tbody>
                    <tr className="mt-2">
                      <td>No buyer available.</td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {allBuyers &&
                      allBuyers.map((data, index) => {
                        return (
                          <tr key={index}>
                            <th scope="row">
                              <Media className="align-items-center">
                                <Media>
                                  <span className="mb-0 text-sm">
                                    {data.username}
                                  </span>
                                </Media>
                              </Media>
                            </th>
                            <td>{data.email}</td>
                            {/* <td>
                            <CircleFlag
                              countryCode={data.country}
                              height="35"
                            />
                          </td>
                          <td>
                            <Badge color="success" className="ml-4">
                              {data.status}
                            </Badge>
                          </td> */}
                            <td>
                              <div className="d-flex align-items-center">
                                <Link to={`/admin/orders/${data?.id}`}>
                                  <Button color="primary" size="sm">
                                    View Order
                                  </Button>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                )}
              </Table>
              {/* <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        2 <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        3
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter> */}
            </Card>
            <div className="d-flex justify-content-center align-items-center mt-3">
              {loader == false ? (
                <>
                  <p className="text-center">
                    {active == false ? "No more items" : ""}
                  </p>
                  <Button
                    onClick={handleLoadMore}
                    className="bg-primary mx-3 text-secondary  mb-2 btn-sm"
                    disabled={active ? false : true}
                  >
                    Load more
                  </Button>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Buyers;
