import firebase from "../../config/firebase";

export const getOrganizationRequests = () => async (dispatch) => {
  try {
    dispatch({
      type: "GET_REQUEST_LOADING",
      payload: true,
    });

    firebase
      .firestore()
      .collection("venderRequests")
      .orderBy("createdAt", "desc")
      .onSnapshot((query) => {
        let Requests = [];
        for (let doc of query.docs) {
          if (doc.exists) {
            Requests.push({ id: doc.id, ...doc.data() });
          }
        }
        dispatch({
          type: "ORGANIZATION",
          payload: Requests,
        });

        dispatch({
          type: "GET_REQUEST_LOADING",
          payload: false,
        });

        console.log({ Requests });
      });
  } catch (error) {
    console.error("Error fetching organization requests:", error);

    dispatch({
      type: "GET_REQUEST_LOADING",
      payload: false,
    });
  }
};

export const updateRequestStatus = (item, status) => async (dispatch) => {
  try {
    firebase
      .firestore()
      .collection("venderRequests")
      .doc(item?.id)
      .update({
        status: status,
      });
    console.log("Ahsan>>>>", item?.id);
    if (status == "accepted") {
      firebase.firestore().collection("users").doc(item?.requestedById).update({
        organization: item?.requestedOrgName,
      });
    }
    alert(`Request ${status}`);
    dispatch({
      type: "UPDATE_ORGANIZATION",
      payload: { ID: item?.id, status },
    });
  } catch (error) {
    console.error("Error updating request status:", error);
  }
};

export const updateWeekRequestStatus = (items, status) => async (dispatch) => {
  try {
    firebase
      .firestore()
      .collection("venderRequests")
      .doc(items?.id)
      .update({
        status: status,
      });
    console.log("Ahsan>>>>", items?.id);
    if (status == "accepted") {
      firebase.firestore().collection("users").doc(items?.requestedById).update({
        weekends: items?.requestedWeekName,

      })
    }
    alert(`Request ${status}`);
    dispatch({
      type: "UPDATE_ORGANIZATION",
      payload: { ID: items?.id, status },
    });
  } catch (error) {
    console.error("Error updating request status:", error);
  }
};

