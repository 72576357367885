import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { addOrUpdateDiscordLink } from "store/actions/settingsAction";

function AddDiscordModel({ isOpen, toggle, data }) {
  const dispatch = useDispatch();
  const { discordLink, addDiscordLoader } = useSelector(
    (state) => state.settings
  );
  const { uid } = useSelector((state) => state.authUser);
  const [setting, setSetting] = useState({
    discordUrl: "",
  });

  const handleInputChange = (e) => {
    setSetting((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      addOrUpdateDiscordLink(uid, setting?.discordUrl, () => {
        toggle();
      })
    );
  };

  useEffect(() => {
    setSetting({
      discordUrl: discordLink?.discordLink,
    });
  }, [discordLink]);
  return (
    <Modal toggle={toggle} isOpen={isOpen}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Discord URL
        </h5>
        <button
          aria-label="Close"
          className="close"
          type="button"
          onClick={toggle}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <ModalBody>
        <Row>
          <Form role="form" onSubmit={handleSubmit} className="w-100 px-4">
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <Input
                  placeholder="Add Discord URL"
                  type="text"
                  value={setting.discordUrl}
                  name="discordUrl"
                  onChange={handleInputChange}
                  // autoComplete="new-password"
                />
              </InputGroup>
            </FormGroup>
            <Row className="justify-content-end">
              <Button color="danger" type="button" onClick={toggle}>
                Close
              </Button>
              <Button color="primary" type="submit">
                {addDiscordLoader ? <Spinner size="sm" /> : "Save Changes"}
              </Button>
            </Row>
          </Form>
        </Row>
      </ModalBody>
    </Modal>
  );
}

export default AddDiscordModel;
