import { GET_SINGLE_BLOG, GET_MORE_BLOGS } from "store/types";
import firebase from "../../config/firebase";

import {
  DELETE_BLOG_LOADER,
  UPDATE_BLOG_LOADER,
  ADD_BLOG_LOADER,
  GET_BLOGS,
} from "store/types";
import { GET_MORE_BLOGS_LOADER } from "store/types";
export const getAllBlogs = () => async (dispatch) => {
  try {
    firebase
      .firestore()
      .collection("posts")
      .orderBy("createdAt", "desc")
      .limit(10)
      .onSnapshot((querySnapshot) => {
        let blogs = [];
        for (let doc of querySnapshot.docs) {
          blogs.push({
            id: doc.id,
            ...doc.data(),
          });
        }

        dispatch({ type: GET_BLOGS, payload: blogs });
      });
  } catch (error) {
    alert("Not Found ! Any Blogs");
  }
};

export const getMoreBlogs = (item) => async (dispatch) => {
  try {
    dispatch({ type: GET_MORE_BLOGS_LOADER, payload: true });

    firebase
      .firestore()
      .collection("posts")
      .orderBy("createdAt", "desc")
      .limit(10)
      .startAfter(item.createdAt)
      .onSnapshot((querySnapshot) => {
        let blogs = [];
        for (let doc of querySnapshot.docs) {
          blogs.push({
            id: doc.id,
            ...doc.data(),
          });
        }

        dispatch({ type: GET_MORE_BLOGS, payload: blogs });
        dispatch({ type: GET_MORE_BLOGS_LOADER, payload: false });
      });
  } catch (error) {
    alert("Not Found ! Any Blogs");
    dispatch({ type: GET_MORE_BLOGS_LOADER, payload: false });
  }
};

export const getSingleBlog = (id) => async (dispatch) => {
  try {
    const doc = await firebase.firestore().collection("posts").doc(id).get();
    const blog = doc.data();

    dispatch({ type: GET_SINGLE_BLOG, payload: blog });
  } catch (error) {
    alert("Blog not found !");
  }
};
export const deleteBlog =
  (blogId, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({ type: DELETE_BLOG_LOADER, payload: true });

      const blogRef = firebase.firestore().collection("posts").doc(blogId);
      await blogRef.delete();

      dispatch({ type: DELETE_BLOG_LOADER, payload: false });
      onSuccess();
    } catch (error) {
      console.error("Error deleting the blog:", error);
    }
  };

export const addBlog =
  ({ title, content }, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({ type: ADD_BLOG_LOADER, payload: true });

      await firebase.firestore().collection("posts").add({
        title: title,
        editor: content,

        createdAt: firebase.firestore.Timestamp.now(),
      });

      onSuccess();
      dispatch({ type: ADD_BLOG_LOADER, payload: false });
    } catch (error) {
      console.error("Error adding/updating the video:", error);
    }
  };

export const updateBlog =
  (blogId, updatedData, onSuccess = () => {}) =>
  async (dispatch) => {
    console.log("blogId, updatedData: ", blogId, updatedData);
    try {
      dispatch({ type: UPDATE_BLOG_LOADER, payload: true });
      await firebase.firestore().collection("posts").doc(blogId).update({
        title: updatedData?.title,
        editor: updatedData?.content,
        updatedAt: firebase.firestore.Timestamp.now(),
      });

      onSuccess();
      dispatch({ type: UPDATE_BLOG_LOADER, payload: false });
    } catch (error) {
      dispatch({ type: UPDATE_BLOG_LOADER, payload: false });

      console.error("Error updating the Blog:", error);
    }
  };
