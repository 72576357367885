import React, { useEffect, useState } from "react";
import OnlyHeader from "components/Headers/OnlyHeader";
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import InviteVendorModal from "components/PopupModels/InviteVendorModel";
import { getVendors } from "store/actions/InviteVendorAction";
import { useDispatch, useSelector } from "react-redux";

const SellerInvites = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();

  const { vendors, loading } = useSelector((state) => state.vendors);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case "accepted":
        return "badge badge-success";
      case "pending":
        return "badge badge-warning";
      default:
        return "badge badge-secondary";
    }
  };

  const handleInviteVendor = () => {
    toggleModal();
  };

  useEffect(() => {
    dispatch(getVendors());
  }, []);

  return (
    <div>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 ">
                <div className="d-flex justify-content-between align-items-center mx-0 mx-lg-3">
                  <h3 className="mb-0">Sellers Invites</h3>
                  <div className="w-50">
                    <div className="position-relative">
                      <Button
                        className="float-right ml-md-auto mr-md-0 ml-sm-auto mr-sm-0"
                        color="primary"
                        onClick={toggleModal}
                      >
                        Invite Vendor
                      </Button>
                    </div>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">createdAt</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Org Name</th>
                    <th scope="col">Status</th>
                    <th scope="col" />
                  </tr>
                </thead>
                {loading ? (
                  <tbody>
                    <tr>
                      <td colSpan="5" className="text-center">
                        <Spinner size="lg" />
                      </td>
                    </tr>
                  </tbody>
                ) : vendors?.length === 0 ? (
                  <tbody>
                    <tr className="mt-2">
                      <td>No invite available.</td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {vendors?.map((vendor, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {vendor?.createdAt?.toDate()?.toLocaleDateString()}
                          </td>
                          <td>{vendor?.name}</td>
                          <td>{vendor?.email}</td>
                          <td>{vendor?.organization}</td>
                          <td>
                            <span
                              className={getStatusBadgeClass(vendor.status)}
                            >
                              {vendor.status}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
      <InviteVendorModal
        isOpen={modalOpen}
        toggle={toggleModal}
        handleInviteVendor={handleInviteVendor}
      />
    </div>
  );
};

export default SellerInvites;
