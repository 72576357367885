import {
  ADD_VIDEO_LOADER,
  GET_VIDEOS,
  DELETE_VIDEO_LOADER,
  GET_MORE_VIDEOS,
  GET_MORE_VIDEOS_LOADER,
} from "../types";

const initialState = {
  allVideos: [],
  addVideoLoader: false,
  deleteVideoLoader: false,
  moreVideos: [],
  loadMoreLoader: false,
};

export default function videosReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ADD_VIDEO_LOADER:
      return {
        ...state,
        addVideoLoader: payload,
      };
    case DELETE_VIDEO_LOADER:
      return {
        ...state,
        deleteVideoLoader: payload,
      };
    case GET_MORE_VIDEOS_LOADER:
      return {
        ...state,
        loadMoreLoader: payload,
      };
    case GET_VIDEOS:
      return {
        ...state,
        allVideos: payload,
      };
    case GET_MORE_VIDEOS:
      return {
        ...state,
        moreVideos: payload,
        allVideos: [...state.allVideos, ...payload],
      };

    default:
      return { ...state };
  }
}
