import { GET_DISCORD } from "store/types";
import firebase from "../../config/firebase";
import { ADD_DISCORD_LOADER } from "store/types";

export const getDiscordLink = () => async (dispatch) => {
  try {
    const docRef = firebase
      .firestore()
      .collection("siteSettings")
      .doc("5qXnY9Bmvxt3nt0g1QIo");

    const doc = await docRef.get();

    dispatch({
      type: GET_DISCORD,
      payload: {
        id: doc.id,
        ...doc.data(),
      },
    });
  } catch (error) {
    console.log("error: ", error);
  }
};

export const addOrUpdateDiscordLink =
  (uid, link, onSuccess = () => {}) =>
  async (dispatch) => {
    console.log("uid, link: ", uid, link);
    dispatch({
      type: ADD_DISCORD_LOADER,
      payload: true,
    });

    try {
      const querySnapshot = await firebase
        .firestore()
        .collection("siteSettings")
        .where("adminID", "==", uid)
        .get();

      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];

        await doc.ref.update({
          discordLink: link,
          updatedAt: firebase.firestore.Timestamp.now(),
        });
        onSuccess();
      } else {
        await firebase.firestore().collection("siteSettings").add({
          adminID: uid,
          discordLink: link,
        });
        onSuccess();
      }

      dispatch({
        type: ADD_DISCORD_LOADER,
        payload: false,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: ADD_DISCORD_LOADER,
        payload: false,
      });
    }
  };
