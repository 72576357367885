import { DASHBOARD_STATS } from "../types";

const initialState = {
  stats: null,
};

export default function dashboardStatsReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case DASHBOARD_STATS:
      return {
        ...state,
        stats: payload,
      };

    default:
      return { ...state };
  }
}
