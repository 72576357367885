import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  Spinner,
} from "reactstrap";

import OnlyHeader from "components/Headers/OnlyHeader.js";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getAllDiputeReviews,
  publishDisputeReview,
} from "store/actions/reviewsAction";
import {
  getDiputeProductReviews,
  updateProductReviews,
} from "store/actions/productReviewAction";
import ReactStars from "react-stars";
import TabSelection from "components/Review/TabSelection";
import debounce from "lodash/debounce";
import { DotPulse } from "@uiball/loaders";

const ReviewDisputes = () => {
  const dispatch = useDispatch();

  const { disputeReviews, sellerLoader, sellerActive, sellerLastDocument } =
    useSelector((state) => state.reviews);
  const {
    disputeProductReviews,
    productLoader,
    productActive,
    productLastDocument,
  } = useSelector((state) => state.productDispute);

  const reviewSections = ["Seller Reviews", "Product Reviews"];

  const [reviewSection, setReviewSection] = useState("Seller Reviews");
  const [sellerSearch, setSellerSearch] = useState("");
  const [productSearch, setProductSearch] = useState("");
  const [isSellerLoading, setisSellerLoading] = useState(false);
  const [isProductLoading, setisProductLoading] = useState(false);
  const [sellerLastDocuments, setsellerLastDocuments] = useState();
  const [productLastDocuments, setproductLastDocuments] = useState();

  const handleSellerReview = debounce(async (e) => {
    try {
      await dispatch(getAllDiputeReviews(null, e.target.value));
      setisSellerLoading(false);
    } catch (error) {
      console.error("An error occurred:", error);
      setisSellerLoading(false);
    }
  }, 3000);

  const handleProductReview = debounce(async (e) => {
    try {
      await dispatch(getDiputeProductReviews(null, e.target.value));
      setisProductLoading(false);
    } catch (error) {
      console.error("An error occurred:", error);
      setisProductLoading(false);
    }
  }, 3000);

  const handleSellerLoadMore = () => {
    setSellerSearch("");
    dispatch(getAllDiputeReviews(sellerLastDocuments, ""));
  };

  const handleProductLoadMore = () => {
    setProductSearch("");
    dispatch(getDiputeProductReviews(productLastDocuments, ""));
  };

  useEffect(() => {
    dispatch(getAllDiputeReviews(null));
  }, []);

  useEffect(() => {
    setsellerLastDocuments(sellerLastDocument);
  }, [sellerLastDocument]);

  useEffect(() => {
    setproductLastDocuments(productLastDocument);
  }, [productLastDocument]);

  useEffect(() => {
    dispatch(getDiputeProductReviews(null));
  }, []);

  return (
    <>
      <OnlyHeader />
      <TabSelection
        options={reviewSections}
        onChange={(value) => setReviewSection(value)}
      />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col review_Dispute">
            {reviewSection === "Seller Reviews" ? (
              <>
                {" "}
                <Card className="shadow">
                  <CardHeader className="border-0 ">
                    <div className="d-flex justify-content-between align-items-center mx-0 mx-lg-3">
                      <h3 className="mb-0">Seller Review</h3>
                      <div className="w-50">
                        <div className="position-relative">
                          <Input
                            className="w-50 float-right mt-1"
                            type="text"
                            placeholder="Search"
                            onChange={(e) => {
                              setisSellerLoading(true);
                              handleSellerReview(e);
                            }}
                          />
                          {isSellerLoading ? (
                            <span
                              style={{
                                position: "absolute",
                                top: "20px",
                                right: "10px",
                              }}
                            >
                              <DotPulse size={25} speed={1.3} color="black" />
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Order ID</th>
                        <th scope="col">Buyer Name</th>
                        <th scope="col">Seller Name</th>
                        <th scope="col">Product Name</th>
                        <th scope="col">Rating</th>
                        <th scope="col">Review</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    {sellerLoader ? (
                      <tbody>
                        <tr>
                          <td colSpan="7" className="text-center">
                            <Spinner size="lg" />
                          </td>
                        </tr>
                      </tbody>
                    ) : disputeReviews?.length === 0 ? (
                      <tbody>
                        <tr className="mt-2">
                          <td>No Reviews available.</td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        {disputeReviews?.map((review, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {review?.createdAt
                                  ?.toDate()
                                  ?.toLocaleDateString()}
                              </td>
                              <td>{review?.orderId}</td>
                              <td>{review?.buyerName}</td>
                              <td>{review?.sellerName}</td>
                              <td>{review?.productName}</td>
                              <td>
                                <div className="rating">
                                  <ReactStars
                                    value={review?.rating}
                                    edit={false}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="review">
                                  {review?.description}
                                </div>
                              </td>
                              <td>
                                <Button
                                  color="success"
                                  size="sm"
                                  onClick={() =>
                                    dispatch(
                                      publishDisputeReview(
                                        review.id,
                                        "published"
                                      )
                                    )
                                  }
                                >
                                  <i className="fa fa-check"> Publish</i>
                                </Button>
                                <Button
                                  color="danger"
                                  size="sm"
                                  onClick={() =>
                                    dispatch(
                                      publishDisputeReview(review.id, "removed")
                                    )
                                  }
                                >
                                  <i className="fa fa-trash"> Remove</i>
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </Table>
                </Card>
                <div className="d-flex justify-content-center align-items-center mt-3">
                  {sellerLoader == false ? (
                    <>
                      <p className="text-center">
                        {sellerActive == false ? "No more items" : ""}
                      </p>
                      <Button
                        onClick={handleSellerLoadMore}
                        className="bg-primary mx-3 text-secondary  mb-2 btn-sm"
                        disabled={sellerActive ? false : true}
                      >
                        Load more
                      </Button>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : (
              <>
                {" "}
                <Card className="shadow">
                  <CardHeader className="border-0 ">
                    <div className="d-flex justify-content-between align-items-center mx-0 mx-lg-3">
                      <h3 className="mb-0">Product Review</h3>
                      <div className="w-50">
                        <div className="position-relative">
                          <Input
                            className="w-50 float-right mt-1"
                            type="text"
                            placeholder="Search"
                            onChange={(e) => {
                              setisProductLoading(true);
                              handleProductReview(e);
                            }}
                          />
                          {isProductLoading ? (
                            <span
                              style={{
                                position: "absolute",
                                top: "20px",
                                right: "10px",
                              }}
                            >
                              <DotPulse size={25} speed={1.3} color="black" />
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Order ID</th>
                        <th scope="col">Product ID</th>
                        <th scope="col">Buyer Name</th>
                        <th scope="col">Seller Name</th>
                        <th scope="col">Product Name</th>
                        <th scope="col">Rating</th>
                        <th scope="col">Review</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    {productLoader ? (
                      <tbody>
                        <tr>
                          <td colSpan="7" className="text-center">
                            <Spinner size="lg" />
                          </td>
                        </tr>
                      </tbody>
                    ) : disputeProductReviews?.length === 0 ? (
                      <tbody>
                        <tr className="mt-2">
                          <td>No Review available.</td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        {disputeProductReviews?.map((review, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {review?.createdAt
                                  ?.toDate()
                                  ?.toLocaleDateString()}
                              </td>
                              <td>{review?.orderId}</td>
                              <td>{review?.productId}</td>
                              <td>{review?.buyerName}</td>
                              <td>{review?.sellerName}</td>
                              <td>{review?.productName}</td>
                              <td>
                                <div className="rating">
                                  <ReactStars
                                    value={review?.rating}
                                    edit={false}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="review">
                                  {review?.description}
                                </div>
                              </td>
                              <td>
                                <Button
                                  color="success"
                                  size="sm"
                                  onClick={() =>
                                    dispatch(
                                      updateProductReviews(
                                        review.id,
                                        "published"
                                      )
                                    )
                                  }
                                >
                                  <i className="fa fa-check"> Publish</i>
                                </Button>
                                <Button
                                  color="danger"
                                  size="sm"
                                  onClick={() =>
                                    dispatch(
                                      updateProductReviews(review.id, "removed")
                                    )
                                  }
                                >
                                  <i className="fa fa-trash"> Remove</i>
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </Table>
                </Card>
                <div className="d-flex justify-content-center align-items-center mt-3">
                  {productLoader == false ? (
                    <>
                      <p className="text-center">
                        {productActive == false ? "No more items" : ""}
                      </p>
                      <Button
                        onClick={handleProductLoadMore}
                        className="bg-primary mx-3 text-secondary  mb-2 btn-sm"
                        disabled={productActive ? false : true}
                      >
                        Load more
                      </Button>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ReviewDisputes;
