import {
  ADD_BLOG_LOADER,
  GET_BLOGS,
  UPDATE_BLOG_LOADER,
  DELETE_BLOG_LOADER,
  GET_SINGLE_BLOG,
  GET_MORE_BLOGS,
  GET_MORE_BLOGS_LOADER,
} from "../types";

const initialState = {
  allBlogs: [],
  singleBlog: null,
  addBlogLoader: false,
  deleteBlogLoader: false,
  editBlogLoader: false,
  moreBlogs: [],
  moreBlogsLoader: false,
};

export default function blogsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ADD_BLOG_LOADER:
      return {
        ...state,
        addBlogLoader: payload,
      };
    case UPDATE_BLOG_LOADER:
      return {
        ...state,
        editBlogLoader: payload,
      };
    case DELETE_BLOG_LOADER:
      return {
        ...state,
        deleteBlogLoader: payload,
      };
    case GET_SINGLE_BLOG:
      return {
        ...state,
        singleBlog: payload,
      };
    case GET_BLOGS:
      return {
        ...state,
        allBlogs: payload,
      };
    case GET_MORE_BLOGS: {
      return {
        ...state,
        moreBlogs: payload,
        allBlogs: [...state.allBlogs, ...payload],
      };
    }
    case GET_MORE_BLOGS_LOADER: {
      return {
        ...state,
        moreBlogsLoader: payload,
      };
    }

    default:
      return { ...state };
  }
}
