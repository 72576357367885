import Index from "views/Index.js";
import Register from "views/auth/Register.js";
import Login from "views/auth/Login.js";
import Buyers from "views/Buyers";
import Seller from "views/Seller";
import ReviewDisputes from "views/ReviewDisputes";
import Blogs from "views/Blogs";
import YoutubeVideos from "views/YoutubeVideos";
import Setting from "views/Setting";
import OrderDetails from "views/Buyers/OrderDetails";
import OrderDetail from "views/seller/OrderDetail";
import ProductDetail from "views/seller/ProductDetail";
import AddBlog from "views/Blog/AddBlog";
import TeamMembers from "views/seller/TeamMembers";
import Distributers from "views/seller/Distributers";
import Categories from "views/Categories";
import EditBlog from "views/Blog/Editblog";
import AllOrders from "views/Buyers/AllOrders";
import SellerInvites from "views/SellerInvites";
import AdminTickets from "views/AdminTickets";
import Requests from "views/Requests";
import TicketDetails from "views/TicketDetails";
import ProductDetails from "views/seller/productDetails";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
  },
  {
    path: "/Buyers",
    name: "Customers & Orders",
    icon: "ni ni-delivery-fast text-blue",
    component: Buyers,
    layout: "/admin",
  },
  {
    path: "/Categories",
    name: "Categories",
    icon: "ni ni-collection text-pink",
    component: Categories,
    layout: "/admin",
  },
  {
    path: "/Sellers",
    name: "Vendors",
    icon: "ni ni-archive-2 text-orange",
    component: Seller,
    layout: "/admin",
  },
  {
    path: "/invite",
    name: "Invite Vendors",
    icon: "ni ni-tag text-blue",
    component: SellerInvites,
    layout: "/admin",
  },

  {
    path: "/reviewDisputes",
    name: "Review Disputes",
    icon: "fa fa-star text-orange",
    component: ReviewDisputes,
    layout: "/admin",
  },
  {
    path: "/adminTickets",
    name: "Tickets",
    icon: "fa fa-list text-green",
    component: AdminTickets,
    layout: "/admin",
  },
  {
    path: "/adminTicket/:id",
    name: "Tickets",
    icon: "fa fa-list text-green",
    component: TicketDetails,
    layout: "/admin",
    isMenu: false,
  },
  {
    path: "/requests",
    name: "Requests",
    icon: "fa fa-envelope text-pink",
    component: Requests,
    layout: "/admin",
  },
  {
    path: "/Setting",
    name: "Site Settings",
    icon: "ni ni-support-16 text-orange",
    component: Setting,
    layout: "/admin",
  },
  {
    path: "/youtubeVideos",
    name: "Youtube Videos",
    icon: "ni ni-button-play text-red",
    component: YoutubeVideos,
    layout: "/admin",
  },
  {
    path: "/Blogs",
    name: "Posts",
    icon: "ni ni-image text-pink",
    component: Blogs,
    layout: "/admin",
  },
  {
    path: "/EditBlog/:id",
    component: EditBlog,
    layout: "/admin",
    isMenu: false,
  },

  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    isMenu: false,
  },

  {
    path: "/orders/:id",
    name: "Order Details",
    icon: "ni ni-planet text-blue",
    component: AllOrders,
    layout: "/admin",
    isMenu: false,
  },

  {
    path: "/orderDetails/:id",
    name: "Order Details",
    icon: "ni ni-planet text-blue",
    component: OrderDetails,
    layout: "/admin",
    isMenu: false,
  },
  {
    path: "/seller/orderDetails/:id",
    name: "Order Details",
    icon: "ni ni-planet text-blue",
    component: OrderDetail,
    layout: "/admin",
    isMenu: false,
  },
  {
    path: "/seller/productDetails/:id",
    name: "Product Details",
    icon: "ni ni-planet text-blue",
    component: ProductDetail,
    layout: "/admin",
    isMenu: false,
  },
  {
    path: "/seller/single-item",
    name: "Product Details",
    icon: "ni ni-planet text-blue",
    component: ProductDetails,
    layout: "/admin",
    isMenu: false,
  },
  {
    path: "/seller/teamMembers/:id",
    name: "TeamMembers",
    icon: "ni ni-planet text-blue",
    component: TeamMembers,
    layout: "/admin",
    isMenu: false,
  },
  {
    path: "/seller/distribution/:id",
    name: "Distribution",
    icon: "ni ni-planet text-blue",
    component: Distributers,
    layout: "/admin",
    isMenu: false,
  },
  {
    path: "/addBlog",
    name: "Add Blog",
    icon: "ni ni-planet text-blue",
    component: AddBlog,
    layout: "/admin",
    isMenu: false,
  },

  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
    isMenu: false,
  },
];
export default routes;
