import firebase from "../../config/firebase";
import { LOGIN, LOGIN_LOADER, LOGOUT } from "../types";

export const LogInLoader = (val) => async (dispatch) => {
  dispatch({
    type: LOGIN_LOADER,
    payload: val,
  });
};

export const login = (creds) => async (dispatch) => {
  console.log("creds: ", creds);
  dispatch(LogInLoader(true));

  try {
    const userCredential = await firebase
      .auth()
      .signInWithEmailAndPassword(creds.email, creds.password);

    const user = userCredential.user.uid;

    const query = await firebase
      .firestore()
      .collection("admin")
      .doc(user)
      .get();

    if (query.exists) {
      const userData = query.data();

      if (userData.isDeleted) {
        alert(
          "Your account data have been deleted from the database. Please contact your admin."
        );
      } else {
        dispatch({
          type: LOGIN,
          payload: { uid: user, user: { id: user, ...userData } },
        });
      }
    } else {
      alert(
        "Account data not found in the database. Please contact your admin."
      );
    }

    dispatch(LogInLoader(false));
  } catch (error) {
    dispatch(LogInLoader(false));

    if (error.code === "auth/invalid-email") {
      alert("Invalid email format. Please enter a valid email address.");
    } else if (
      error.code === "auth/wrong-password" ||
      error.code === "auth/user-not-found"
    ) {
      alert("Invalid email or password. Please try again.");
    } else if (
      error.code === "auth/internal-error" &&
      error.message.includes("INVALID_LOGIN_CREDENTIALS")
    ) {
      alert("Invalid email or password. Please try again.");
    } else {
      alert("An error occurred. Please try again later.");
    }
  }
};

//LOGIN WITH GOOGLE FUNCTIONALITY
export const AuthWithGoogle = () => async (dispatch) => {
  const provider = new firebase.auth.GoogleAuthProvider();
  firebase
    .auth()
    .signInWithPopup(provider)
    .then(async (data) => {
      console.log("Data of auth", data);
      firebase
        .firestore()
        .collection("admin")
        .doc(data.user.uid)
        .onSnapshot(async (query) => {
          if (query.data()) {
            if (query.data().isDeleted) {
              alert(
                "Your account data have been deleted from database. But the good news is, your account is still alive. Please contact your admin to create a new slot for you to store data then you will be able to login."
              );
              dispatch({
                type: LOGOUT,
              });
            } else {
              dispatch(LogInLoader(true));

              dispatch({
                type: LOGIN,
                payload: {
                  uid: data.user.uid,
                  user: { id: data.user.uid, ...query.data() },
                },
              });
              dispatch(LogInLoader(false));
            }
          } else {
            firebase
              .firestore()
              .collection("admin")
              .doc(data.user.uid)
              .set({
                email: data.user.email,
                createdAt: firebase.firestore.Timestamp.now(),
              })
              .then((res) => {
                firebase.firestore().collection("users").doc(data.user.uid);
              })
              .catch((err) => {
                alert(err, "danger");
              });
          }
        });
    })
    .catch((err) => {
      alert(err, "danger");
    });
};
//LOGOUT FUNTIONALITY
export const logout = () => async (dispatch) => {
  firebase
    .auth()
    .signOut()
    .then((data) => {
      localStorage.clear();
      window.location.href = "/auth/login";
    })
    .catch((error) => {});
};
