import {
  Card,
  CardHeader,
  Media,
  Table,
  Container,
  Row,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Col,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  Badge,
} from "reactstrap";
// core components
import OnlyHeader from "components/Headers/OnlyHeader.js";
import OrderCard from "components/common/OrderCard";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersAction } from "store/actions/ordersAction";
import { getOrderItemsAction } from "store/actions/ordersAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { MdArrowBackIosNew } from "react-icons/md";

const OrderDetails = () => {
  const { orderItems } = useSelector((state) => state.orders);
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (id) {
      dispatch(getOrderItemsAction(id));
    }
  }, [id]);

  return (
    <>
      <OnlyHeader />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Col>
              <Card className="mb-4">
                <CardBody className="mainCardHeader">
                  <Row>
                    <Col lg={3} className="d-flex align-items-center">
                      <MdArrowBackIosNew
                        className="mx-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => history.goBack()}
                      />
                      <h3 className="mb-0  text-capitalize">Order Items</h3>
                    </Col>
                  </Row>
                  {orderItems
                    ? orderItems?.map((item, index) => (
                        <div key={index}>
                          <OrderCard data={item} />
                        </div>
                      ))
                    : "Order Items Not Found !"}
                </CardBody>
              </Card>
            </Col>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default OrderDetails;
