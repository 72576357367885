import firebase from "../../config/firebase";

export const getTickets = (currentCategory) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_TICKETS_LOADING",
      payload: true,
    });

    const ticketsQuery = firebase.firestore().collection("platformTickets");
    let querySnapshot;

    if (currentCategory !== "All") {
      querySnapshot = await ticketsQuery
        .where("category", "==", currentCategory)
        .orderBy("createdAt", "desc")
        .get();
    } else {
      querySnapshot = await ticketsQuery.orderBy("createdAt", "desc").get();
    }
    let tickets = [];
    querySnapshot.forEach((doc) => {
      if (doc.exists) {
        tickets.push({
          id: doc.id,
          ...doc.data(),
        });
      }
    });
    dispatch({
      type: "GET_TICKETS",
      payload: tickets,
    });
    dispatch({
      type: "GET_TICKETS_LOADING",
      payload: false,
    });
  } catch (error) {
    console.error("Error fetching tickets:", error);
    dispatch({
      type: "GET_TICKETS_LOADING",
      payload: false,
    });
  }
};

export const updateTicketStatus = (newStatus, ID) => {
  return async (dispatch) => {
    try {
      const ticketRef = firebase
        .firestore()
        .collection("platformTickets")
        .doc(ID);
      await ticketRef.update({
        status: newStatus,
      });

      dispatch({
        type: "UPDATE_TICKET_STATUS",
        payload: { ID, status: newStatus },
      });
    } catch (error) {
      console.error("Error updating ticket status:", error);
    }
  };
};

export const getPlateformCategories = () => async (dispatch) => {
  try {
    await firebase
      .firestore()
      .collection("ticketCategories")
      .doc("platformTickets")
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          dispatch({
            type: "PLATEFORM_TICKETS",
            payload: data?.categories,
          });
        }
      });
  } catch (error) {
    console.log({ error });
  }
};

export const getSingleTicket = (ID) => {
  return async (dispatch) => {
    try {
      const ticketRef = firebase
        .firestore()
        .collection("platformTickets")
        .doc(ID);
      const ticketSnapshot = await ticketRef.get();
      const ticketData = ticketSnapshot.data();
      dispatch({
        type: "GET_SINGLE_TICKET",
        payload: { ID, ticketData },
      });
    } catch (error) {
      console.error("Error getting single ticket:", error);
    }
  };
};

export const updateHistory = (newHistory, ID) => {
  return async (dispatch) => {
    try {
      const ticketRef = firebase
        .firestore()
        .collection("platformTickets")
        .doc(ID);
      const ticketSnapshot = await ticketRef.get();
      const currentData = ticketSnapshot.data();

      const currentHistory = currentData.history || [];

      const updatedHistory = [newHistory, ...currentHistory];

      await ticketRef.update({
        history: updatedHistory,
      });

      dispatch({
        type: "ADD_HISTORY",
        payload: { ID, history: updatedHistory },
      });
    } catch (error) {
      console.error("Error updating history:", error);
    }
  };
};
