export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const LOGIN_LOADER = "LOGIN_LOADER";

// BLOGS TYPES
export const ADD_BLOG_LOADER = "ADD_BLOG_LOADER";
export const GET_BLOGS = "GET_BLOGS";
export const UPDATE_BLOG = "UPDATE_BLOG";
export const DELETE_BLOG_LOADER = "DELETE_BLOG_LOADER";
export const UPDATE_BLOG_LOADER = "UPDATE_BLOG_LOADER";
export const GET_SINGLE_BLOG = "GET_SINGLE_BLOG";
export const GET_MORE_BLOGS = "GET_MORE_BLOGS";
export const GET_MORE_BLOGS_LOADER = "GET_MORE_BLOGS_LOADER";

//VIDEOS TYPES

export const ADD_VIDEO_LOADER = "ADD_VIDEO_LOADER";
export const GET_VIDEOS = "GET_VIDEOS";
export const DELETE_VIDEO_LOADER = "DELETE_VIDEO_LOADER";
export const GET_MORE_VIDEOS = "GET_MORE_VIDEOS";
export const GET_MORE_VIDEOS_LOADER = "GET_MORE_VIDEOS_LOADER";

//CATEGORY TYPES
export const ADD_CATEGORY_LOADER = "ADD_CATEGORY_LOADER";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const DELETE_CATEGORY_LOADER = "DELETE_CATEGORY_LOADER";
export const UPDATE_CATEGORY_LOADER = "UPDATE_CATEGORY_LOADER";

//DISCORD TYPES
export const ADD_DISCORD_LOADER = "ADD_DISCORDLOADER";
export const GET_DISCORD = "GET_DISCORD";

//SELLERS
export const GET_SELLERS = "GET_SELLERS";
export const GET_DISTRIBUTERS = "GET_DISTRIBUTERS";
export const GET_TEAMMEMBERS = "GET_TEAMMEMBERS";
export const GET_ITEMS = "GET_ITEMS";
export const GET_ORDER = "GET_ORDER";

//BUYERS
export const GET_BUYERS = "GET_BUYERS";

//DASHBOARD_STATS

export const DASHBOARD_STATS = "DASHBOARD_STATS";

//ORDERS
export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDERS_ITEMS = "GET_ORDERS_ITEMS";

//REVIEWS

export const GET_DISPUTE_REVIEWS = "GET_DISPUTE_REVIEWS";
export const UPDATE_DISPUTE_REVIEW = "UPDATE_DISPUTE_REVIEW";

//Product Reviews

export const GET_PRODUCT_DISPUTE_REVIEWS = "GET_PRODUCT_DISPUTE_REVIEWS";
export const UPDATE_PRODUCT_DISPUTE_REVIEW = "UPDATE_PRODUCT_DISPUTE_REVIEW";

//Invite Vendors

export const GET_VENDORS = "GET_VENDORS";
export const ADD_VENDOR = "ADD_VENDOR";
