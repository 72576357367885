import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Spinner,
  Table,
} from "reactstrap";
// core components
import OnlyHeader from "components/Headers/OnlyHeader.js";
import AddCategoryModel from "components/PopupModels/AddCategory";
import DeleteCategoryModel from "components/PopupModels/DeleteCategoryModal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategories } from "store/actions/categoriesAction";
let countryFlag = ["ca", "us", "bs", "ai", "gd", "as", "vc", "pk", "tr", "jo"];

const Categories = () => {
  const dispatch = useDispatch();
  const { allCategories } = useSelector((state) => state.categories);
  const [itemsToShow, setItemsToShow] = useState(10);
  const [editData, setEditData] = useState("");
  const [categoryData, setCategoryData] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);

  const loadMore = () => {
    setItemsToShow(itemsToShow + 10);
  };
  const loadLess = () => {
    setItemsToShow(10);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredCategories = allCategories.filter((category) =>
    category.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
const sortedCategories = [...filteredCategories].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      await dispatch(getAllCategories());
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };

    fetchData();
  }, []);


  return (
    <>
      <OnlyHeader />
      <AddCategoryModel
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(!isModalOpen)}
        data={editData}
      />
      <DeleteCategoryModel
        isOpen={isCategoryModalOpen}
        toggle={() => setIsCategoryModalOpen(!isCategoryModalOpen)}
        data={categoryData}
      />
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-lg-flex w-100 justify-content-between">
                <h3 className="mb-0">Categories</h3>
                <div style={{maxWidth:"200px"}}>
                  <InputGroup className="input-group-alternative ml-0 ml-lg-4 my-3 my-lg-0 w-100">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Search"
                      type="text"
                      value={searchQuery}
                      onChange={handleSearch}
                    />{" "}
                  </InputGroup>
                </div>
                <Button
                  size="md"
                  color="primary"
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                >
                  Create
                </Button>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Products Sold</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                {isLoading ? (
                  <tbody>
                    <tr>
                      <td colSpan="5" className="text-center">
                        <Spinner size="lg" />
                      </td>
                    </tr>
                  </tbody>
                ) : sortedCategories.length === 0 ? (
                  <tbody>
                    <tr className="mt-2">
                      <td>No category available.</td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {sortedCategories
                      .slice(0, itemsToShow)
                      .map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>{data.name}</td>
                            <td>
                              {data?.productsSold ? data?.productsSold : "N/A"}
                            </td>
                            <td>
                              <Button
                                color="success"
                                size="sm"
                                onClick={() => {
                                  setIsModalOpen(true);
                                  setEditData(data);
                                }}
                              >
                                <i className="fa fa-edit"></i>
                              </Button>
                              <Button
                                color="danger"
                                size="sm"
                                onClick={() => {
                                  setIsCategoryModalOpen(true);
                                  setCategoryData(data);
                                }}
                              >
                                <i className="fa fa-trash"></i>
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                )}
              </Table>
            </Card>
          </div>
        </Row>
        <Row>
          <Col className="my-3">
            <div className="d-flex justify-content-center align-items-center">
              {itemsToShow < allCategories?.length ? (
                <Button onClick={loadMore} size="sm" color="primary">
                  Load More
                </Button>
              ) : itemsToShow > 10 ? (
                <Button onClick={loadLess} size="sm" color="warning">
                  Show Less
                </Button>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Categories;
