import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Media,
  Row,
  Spinner,
  Table,
} from "reactstrap";
// core components
import OnlyHeader from "components/Headers/OnlyHeader.js";

import DeletePostModel from "components/PopupModels/DeletePostModal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllBlogs } from "store/actions/blogsAction";
import { getMoreBlogs } from "store/actions/blogsAction";

const Blogs = () => {
  const dispatch = useDispatch();
  const { allBlogs, deleteBlogLoader, moreBlogsLoader } = useSelector(
    (state) => state.blogs
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [blogData, setBlogData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const filterOutImages = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    const imgElements = doc.querySelectorAll("img");
    imgElements.forEach((img) => {
      img.parentNode.removeChild(img);
    });

    return doc.body.innerHTML;
  };

  const loadMore = () => {
    const lastItem = allBlogs[allBlogs.length - 1];
    dispatch(getMoreBlogs(lastItem));
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredPosts = allBlogs.filter((blog) =>
    blog.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      await dispatch(getAllBlogs());
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };

    fetchData();
  }, []);

  return (
    <>
      <DeletePostModel
        isOpen={isModalOpen}
        data={blogData}
        toggle={() => setIsModalOpen(!isModalOpen)}
      />
      <OnlyHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-lg-flex w-100 justify-content-between">
                <h3 className="mb-0">Posts</h3>
                <div style={{maxWidth:"200px"}}>
                  <InputGroup className="input-group-alternative ml-0 ml-lg-4 my-3 my-lg-0 w-100">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Search"
                      type="text"
                      value={searchQuery}
                      onChange={handleSearch}
                    />{" "}
                  </InputGroup>
                </div>
                <Link to={"/admin/addBlog"}>
                  <Button color="primary">Add Post</Button>
                </Link>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Title</th>
                    <th scope="col">Details</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                {isLoading ? (
                  <tbody>
                    <tr>
                      <td colSpan="5" className="text-center">
                        <Spinner size="lg" />
                      </td>
                    </tr>
                  </tbody>
                ) : filteredPosts.length === 0 ? (
                  <tbody>
                    <tr className="mt-2">
                      <td>No post available.</td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {filteredPosts.map((data, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm truncate">
                                  {data?.title}
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td>
                            <div
                            className="detail"
                              dangerouslySetInnerHTML={{
                                __html: filterOutImages(data?.editor),
                              }}
                            />
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <Link to={`/admin/EditBlog/${data?.id}`}>
                                <Button color="primary" size="sm">
                                  <i className="fas fa-pencil-alt" />
                                </Button>
                              </Link>
                              <Button
                                color="danger"
                                className="mx-2"
                                onClick={() => {
                                  setBlogData(data);
                                  setIsModalOpen(true);
                                }}
                                size="sm"
                                disabled={deleteBlogLoader}
                              >
                                <i className="fa fa-trash"></i>
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </Table>
            </Card>
          </div>
        </Row>
        <Row>
          <Col className="my-3">
            <div className="d-flex justify-content-center align-items-center">
              <Button
                onClick={loadMore}
                disabled={allBlogs?.length < 10 ? true : false}
                size="sm"
                color="primary"
              >
                {moreBlogsLoader ? <Spinner size="sm" /> : "Load More"}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Blogs;
